/* eslint no-param-reassign: "off" */
/* eslint camelcase: "off" */
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import httpClient from 'utilities/httpClient';

export interface Account {
  email: string;
  id: number;
  // todo: check language types
  languageCode: 'en' | 'de';
  name: string;
  last___name: string;
  mobile?: number;
  organization___id: number;
  organization___name: string;
  password: string;
  role: 'USER' | 'MODERATOR' | 'ADMIN' | 'SUPER_ADMIN';
  status: 'ACTIVE' | 'INACTIVE';
  timeCreated: number;
  username: string;
}

const accountsAdapter = createEntityAdapter<Account>({
  sortComparer: (a, b) => (a.id > b.id ? -1 : 1),
});

export const fetchAccounts = createAsyncThunk(
  'account/fetchAll',
  async take => {
    const response = await httpClient.post('/service/rest/account', take);
    return response.data.data;
  }
);

export const fetchAccountById = createAsyncThunk(
  'account/fetchOne',
  async id => {
    const response = await httpClient.get(`/service/rest/account/${id}`);
    return response.data;
  }
);

export const modifyAccount = createAsyncThunk(
  'account/modifyAccount',
  async body => {
    const response = await httpClient.post(
      `/service/rest/account/modify`,
      body
    );
    return response.data;
  }
);

export const deleteAccount = createAsyncThunk('account/deleteOne', async id => {
  await httpClient.delete(`/service/rest/account/${id}/delete`);
  return Number(id);
});

const accountsSlice = createSlice({
  name: 'account',
  initialState: accountsAdapter.getInitialState({
    // 'idle' | 'loading' | 'error' | 'succeeded' | 'updating' | 'deleting'
    status: 'idle',
    error: {},
  }),
  reducers: {},
  extraReducers(builder) {
    builder
      // fetch all
      .addCase(fetchAccounts.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchAccounts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        accountsAdapter.setAll(state, action.payload);
      })
      .addCase(fetchAccounts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      }) // fetch one
      .addCase(fetchAccountById.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchAccountById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        accountsAdapter.upsertOne(state, action.payload);
      })
      .addCase(fetchAccountById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      })
      // create / modify one
      .addCase(modifyAccount.pending, state => {
        state.status = 'updating';
      })
      .addCase(modifyAccount.fulfilled, (state, action) => {
        state.status = 'succeeded';
        accountsAdapter.upsertOne(state, action.payload);
      })
      // deleting
      .addCase(deleteAccount.pending, state => {
        state.status = 'deleting';
      })
      .addCase(deleteAccount.fulfilled, (state, action) => {
        state.status = 'succeeded';
        accountsAdapter.removeOne(state, action.payload);
      })
      .addCase(deleteAccount.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

export default accountsSlice.reducer;

// memoised selectors
export const { selectAll: selectAllAccounts, selectById: selectAccountById } =
  accountsAdapter.getSelectors<RootState>(state => state.accounts);
