// TODO: refactor this component

import React from 'react';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  format,
  isBefore,
  isAfter,
  isWithinRange,
  startOfDay,
  endOfDay,
} from 'date-fns';
import { MdDone, MdClear } from 'react-icons/md';
import DateRangePicker from '../components/shared/DateRangePicker';
import { t } from '@lingui/macro';
import {
  network,
  supportTypes,
  language,
  electrodeTypes,
} from '../config/enums';

export interface ColumnType {
  Header: string;
  accessor: string;
  width?: number;
  default?: boolean;
  type?: 'string' | 'enum' | 'number' | 'date' | 'boolean';
  enums?: string[];
  Cell?: any;
}

const enumValues = {
  currentDisabilities: [
    'Other disability',
    'Blind/partially sighted',
    'Speech disorder',
    'Hard of hearing',
    'Reduced mobility',
    'Children',
  ],
  band: network.band,
  networkMode: network.accessMode,
  //! todo: this could become problematic with other 'type' fields
  type: ['TELEMETRY', 'USER_ACTION'],
  language,
  electrode_type: electrodeTypes,
};

const parseBitmask = (bitmaskString: string) => {
  if (!bitmaskString) return '';
  const bitmask = bitmaskString.split('');
  const values = [
    // Stop and support do not need to be displayed here
    null,
    null,
    ...supportTypes,
  ];
  const disabilitiesArr: string[] = [];
  bitmask.forEach((bit, i) => {
    if (i < 2) return;
    if (Number(bit) && values[i]) disabilitiesArr.push(values[i] as string);
  });
  return disabilitiesArr.join(', ');
};

const getEnumKeyValue = (i: number, accessor: keyof typeof enumValues) =>
  enumValues[accessor][i] as string;

// FILTER METHODS

// contains method determines whether contains or startsWith runs
// cb is applied to the value being filtered beforehand
const stringFilterMethod =
  (accessor: string, exact?: boolean, cb?: (row: any) => void) =>
  (filter: any, row: any) => {
    if (!row[accessor] && row !== 0 && row !== '0') return false;
    let toFilter = cb ? cb(row[accessor]) : row[accessor];
    if (toFilter === null || toFilter === '') return false;
    toFilter = toFilter.toString().toLowerCase();
    const filterValue = filter.value.toLowerCase();
    return exact ? toFilter === filterValue : toFilter.includes(filterValue);
  };

const dateFilterMethod = (key: string) => (filter: any, row: any) => {
  if (!filter.value) return true;
  let { startDate, endDate } = filter.value;
  // no filter value
  if (!startDate && !endDate) return true;
  startDate = startDate ? startOfDay(startDate) : null;
  endDate = endDate ? endOfDay(endDate) : null;
  // only a start date
  if (startDate && !endDate) return isAfter(row[key], startDate);
  // only an end date
  if (!startDate && endDate) return isBefore(row[key], endDate);
  // both a start and an end date
  if (startDate && endDate) return isWithinRange(row[key], startDate, endDate);
  return false;
};

const boolFilterMethod =
  (accessor: string, trueValue = true) =>
  (filter: any, row: any) => {
    const value = row._original[accessor];
    if (value === '') return true;
    return (value === trueValue) === filter.value;
  };

// COLUMN CREATOR FUNCTION
export type ColumnCreatorType = {
  [x: string]: any;
  Header: any;
  accessor: any;
  type?: string | undefined;
  enums?: any | undefined;
  filterable?: boolean | undefined;
  textAlign?: string | undefined;
  width: any;
};

const columnCreator = ({
  Header,
  accessor,
  type = 'string',
  enums,
  filterable = true,
  textAlign = 'center',
  width,
  ...rest
}: ColumnCreatorType) => {
  let filterMethod;
  let Filter;
  let Cell;

  if (type === 'string' || type === 'number') {
    filterMethod = stringFilterMethod(accessor);
    Filter = function textFilter({ filter, onChange }: any) {
      return (
        <TextField
          value={filter ? filter.value : ''}
          onChange={e => onChange(e.target.value)}
          name={accessor}
          //! issue with html5 number type and comma/punkt divider - I _would_ like to accept both
          //! https://stackoverflow.com/questions/35315157/html5-input-box-with-type-number-does-not-accept-comma-in-chrome-browser
          // type={type}
          fullWidth
        />
      );
    };
    Cell = ({ value }: { value?: string }) =>
      value !== undefined && value !== null ? value : '-';
  } else if (type === 'date') {
    filterMethod = dateFilterMethod(accessor);
    Filter = function dateFilter({ filter, onChange }: any) {
      return (
        <DateRangePicker
          value={filter ? filter.value : ''}
          onChange={onChange}
          popperPlacement="top-start"
        />
      );
    };
    Cell = ({ value }: { value?: string }) =>
      value !== undefined && value !== null
        ? format(value, 'DD.MM.YYYY HH:mm:ss')
        : '-';
  } else if (type === 'boolean') {
    filterMethod = boolFilterMethod(accessor);
    Filter = function boolFilter({ filter, onChange }: any) {
      return (
        <Select
          fullWidth
          value={filter ? filter.value : ''}
          onChange={({ target }) => onChange(target.value)}
        >
          <MenuItem value="">{t`All`}</MenuItem>
          <MenuItem value={1}>{t`True`}</MenuItem>
          <MenuItem value={0}>{t`False`}</MenuItem>
        </Select>
      );
    };
    // eslint-disable-next-line react/display-name
    Cell = ({ value }: { value?: string }) => {
      if (value === undefined || value === null) return '-';
      return value ? <MdDone /> : <MdClear />;
    };
  } else if (type === 'enum') {
    // todo: enums can just be sent to this function
    // (then they can be unique or come from a shared file)
    let cb = null as unknown as ((row: any) => string) | undefined;
    if (accessor === 'currentDisabilities' || accessor === 'disabilityTypes')
      cb = (bm: string) => parseBitmask(bm);
    if (
      accessor === 'band' ||
      accessor === 'language' ||
      accessor === 'electrode_type'
    )
      cb = i => getEnumKeyValue(i, accessor);
    filterMethod = stringFilterMethod(accessor, false, cb);
    Filter = function enumFilter({ filter, onChange }: any) {
      return (
        <Select
          fullWidth
          value={filter ? filter.value : ''}
          onChange={({ target }) => onChange(target.value)}
        >
          <MenuItem value="">{t`All`}</MenuItem>
          {enums.map((val: string) => (
            <MenuItem key={val} value={val}>
              {val}
            </MenuItem>
          ))}
        </Select>
      );
    };
    Cell = ({ value }: { value?: string }) => {
      if (value === null || value === undefined) return '-';
      if (cb) return cb(value) || value || '-';
      return value || '-';
    };
  }

  return {
    Header,
    headerStyle: { overflow: 'visible' },
    accessor,
    Cell,
    filterable,
    Filter,
    filterMethod,
    width: width || 190,
    resizable: type !== 'date',
    style: {
      textAlign,
    },
    ...rest,
  };
};

export default columnCreator;
