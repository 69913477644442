import ComponentTitle from 'components/shared/ComponentTitle';
import { useEffect, useCallback } from 'react';
import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  fetchOrganizations,
  modifyListOrganizations,
  Organization,
  selectAllOrganizations,
} from 'redux/slices/organizations';
import styled from 'styled-components';
import OrganizationsTable from './OrganizationsTable';
import OrganizationTree from './OrganizationTree';

const Container = styled.div`
  margin-bottom: 2rem;
`;

const Customers = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const tableRefresh = useCallback(() => {
    dispatch(fetchOrganizations());
  }, [dispatch]);

  useEffect(() => tableRefresh(), [dispatch, tableRefresh]);

  const organizations = useAppSelector(selectAllOrganizations);

  const status = useAppSelector(state => state.organizations.status);

  const onAddNewOrg = () => {
    return history.push('customer/add');
  };

  const onEditOrg = (id: number) => {
    return history.push(`/customers/edit/${id}`);
  };

  const handleSave = async (newOrganizations: Organization[]) => {
    try {
      await dispatch(modifyListOrganizations(newOrganizations)).unwrap();

      toast.success(t`The customers were successfully modified`);
    } catch (err) {
      toast.error(t`There was a problem modifying the customers`);
      console.log('Error is: ', err);
    }
  };

  return (
    <Container>
      <OrganizationsTable
        organizations={organizations}
        status={status}
        tableRefresh={tableRefresh}
        onAddOrg={onAddNewOrg}
        onEdit={onEditOrg}
      />
      <ComponentTitle>{t`Customer tree`}</ComponentTitle>
      <OrganizationTree
        organizations={organizations}
        status={status}
        onSave={handleSave}
      />
    </Container>
  );
};

export default Customers;
