export default (major, minor, patch, build, fallback = null) => {
  if (
    major === null ||
    major === undefined ||
    minor === null ||
    minor === undefined
  )
    return fallback;
  return `v${major}.${minor}${
    patch !== null && patch !== undefined ? `.${patch}` : ''
  }${build !== null && build !== undefined ? `.${build}` : ''}`;
};
