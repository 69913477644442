import { t } from '@lingui/macro';
const columns = [
  {
    Header: t`ID`,
    accessor: 'id',
    type: 'number',
    width: 70,
    default: true,
  },
  {
    Header: t`Name`,
    accessor: 'name',
    width: 150,
    default: true,
  },
  {
    Header: t`City`,
    accessor: 'city',
    width: 150,
    default: true,
  },
  {
    Header: t`Street`,
    accessor: 'street',
    width: 100,
    default: true,
  },
  {
    Header: t`HouseNumber`,
    accessor: 'houseNumber',
    width: 100,
    default: true,
  },
  {
    Header: t`Postcode`,
    accessor: 'postcode',
    width: 100,
    default: true,
  },
  {
    Header: t`Country`,
    accessor: 'country',
    width: 150,
    default: true,
  },
  {
    Header: t`Time created`,
    accessor: 'timeCreated',
    type: 'date',
    width: 150,
    default: true,
  },
];

export default columns;
