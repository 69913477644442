import { createContext, useEffect, useState, useCallback } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { fetchAed, fetchMapDataWithLatestEvents } from 'redux/slices/devices';

export const DashboardContext = createContext({
  aed: [] as any[],
  mapData: [] as any[], // [] as any[] | undefined // eslint-disable-line,
  initialAedData: [] as any[],
  selectedDeviceId: -1 as number,
  setSelectedDeviceId: (deviceId: number) => {
    //
  },
  fetchingMap: false as boolean,
  loadingTableData: false as boolean,
  handleRefresh: () => {
    //
  },
  setOrganizationId: (organizationId: number) => {
    //
  },
  organizationId: -1 as number,
  devicesStateArray: [] as any[],
  setDevicesStateArray: (deviceStateArray: any[]) => {
    //
  },
});

const initDevicesStateArray = [
  { state: 'READY', checked: true, color: '#549612' },
  { state: 'NOT SEEN', checked: true, color: '#333b4b' },
  {
    state: 'ALERT ORANGE',
    checked: true,
    color: '#f5a623',
  },
  {
    state: 'ALERT RED',
    checked: true,
    color: '#d54138',
  },
  { state: 'MOVED', checked: true, color: '#9370DB' },
];

export const DashboardProvider: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const [aed, setAedData] = useState<any[]>([]);
  const [mapData, setMapData] = useState<any[]>([]);

  const [initialAedData, setInitialAedData] = useState<any[]>([]);
  const [initialMapData, setInitialMapData] = useState<any[]>([]);

  const [selectedDeviceId, setSelectedDeviceId] = useState(-1);

  const [fetchingMap, setFetchingMap] = useState<boolean>(false);
  const [loadingTableData, setLoadingTableData] = useState<boolean>(false);

  const allOrganizationsSelectedId = -1; // all organization are selected
  const [organizationId, setOrganizationId] = useState(
    allOrganizationsSelectedId
  );

  const [devicesStateArray, setDevicesStateArray] = useState<any[]>(
    initDevicesStateArray
  );

  const fetchEntities = useCallback(async () => {
    setFetchingMap(true);
    setLoadingTableData(true);

    // SLOW AF, FIX IS BELOW
    // const responseAedData = await dispatch(fetchAed());
    // const responseMapData = await dispatch(fetchMapDataWithLatestEvents());

    let responseAedData: any = null;
    let responseMapData: any = null;

    const doEverything = async () => {
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*  @ts-ignore */}
      return dispatch => Promise.all([
        dispatch(fetchAed()),
        dispatch(fetchMapDataWithLatestEvents())
      ]).then((result) => {
        for (let index = 0; index < result?.length; index++) {
          const element = result[index];
          if (element?.type === 'device/fetchAedData/fulfilled') {
            responseAedData = element;
          }
          if (element?.type === 'device/fetchMapDataWithLatestEvents/fulfilled') {
            responseMapData = element;
          }
        }
      });
    };

    await dispatch(await doEverything()).then(() => {
      console.log('!');
    });

    const filterDevices = (devicesArray: any[], isMapArray: boolean): any[] => {
      return (
        devicesArray
          .filter(
            device =>
              organizationId === allOrganizationsSelectedId ||
              (isMapArray
                ? device.organizationId === organizationId
                : device.organization___id === organizationId)
          )
          // filter by device state
          .filter(
            device =>
              devicesStateArray.every(value => value.checked) ||
              devicesStateArray.filter(
                value => value.checked && value.state === device.state
              ).length
          )
      );
    };

    const aedDevicesArray = filterDevices(initialAedData, false);
    const mapDevicesArray = filterDevices(initialMapData, true);

    setAedData(aedDevicesArray);
    setAedData(mapDevicesArray);

    setInitialAedData(responseAedData?.payload);
    setInitialMapData(responseMapData?.payload);

    setFetchingMap(false);
    setLoadingTableData(false);
  }, []);

  useEffect(() => {
    fetchEntities();
  }, []);

  // filter devices by organization id and device state (READY, MOVED, NOT SEEN ....)
  useEffect(() => {
    const filterDevices = (devicesArray: any[], isMapArray: boolean): any[] => {
      return (
        devicesArray
          ?.filter(
            device =>
              organizationId === allOrganizationsSelectedId ||
              (isMapArray
                ? device.organizationId === organizationId
                : device.organization___id === organizationId)
          )
          // filter by device state
          ?.filter(
            device =>
              devicesStateArray.every(value => value.checked) ||
              devicesStateArray.filter(
                value => value.checked && value.state === device.state
              ).length
          )
      );
    };

    const aedDevicesArray = filterDevices(initialAedData, false);
    const mapDevicesArray = filterDevices(initialMapData, true);

    setAedData(aedDevicesArray);
    setMapData(mapDevicesArray);
  }, [devicesStateArray, organizationId, initialAedData, initialMapData]);

  return (
    <DashboardContext.Provider
      value={{
        aed,
        mapData,
        initialAedData,
        selectedDeviceId,
        setSelectedDeviceId,
        fetchingMap,
        loadingTableData,
        handleRefresh: fetchEntities,
        setOrganizationId,
        organizationId,
        devicesStateArray,
        setDevicesStateArray,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
