import styled from 'styled-components';

export default styled.h3`
  margin: 0.6rem 0;
  padding: 0.2rem 0 0.2rem 1rem;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 300;
  border-left: 2px solid #36d4a9;
  font-family: 'Barlow', sans-serif;
  position: relative;
`;
