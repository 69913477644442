import React, { useEffect, useState } from 'react';
import httpClient from 'utilities/httpClient';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { t } from '@lingui/macro';
import Loader from './Loader';

interface CheckDeleteDialogProps {
  deleteId: number;
  onClose: () => void;
  onDelete: () => void;
  isDeleting: boolean;
}

const CheckDeleteDialog: React.FC<CheckDeleteDialogProps> = ({
  deleteId,
  onClose,
  onDelete,
  isDeleting,
}) => {
  // const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isReadyToDelete, setIsReadyToDelete] = useState<boolean>(true);

  useEffect(() => {
    const checkIsReadyTodelete = async () => {
      try {
        setIsLoading(true);

        const response = await httpClient.get(
          `/service/rest/organization/isAvailableForDelete/${deleteId}`
        );

        setIsLoading(false);
        setIsReadyToDelete(response.data);
      } catch (err) {
        console.log('Error is: ', err);
        setIsReadyToDelete(false);
        setIsLoading(false);
      }
    };

    checkIsReadyTodelete();
  }, []);

  let content;
  if (isLoading) {
    content = <Loader />;
  } else if (!isReadyToDelete) {
    content = (
      <>
        <DialogContent id="alert-dialog-description">
          {t`Not yet ready to delete. Maybe this organization has some devices or account.`}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t`Cancel`}
          </Button>
        </DialogActions>
      </>
    );
  } else {
    content = (
      <>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t`Are you sure you want to delete this`}?{' '}
            {t`It may be irreversible.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t`Cancel`}
          </Button>
          <Button onClick={onDelete} color="primary" autoFocus>
            {isDeleting ? <Loader button /> : t`Confirm`}
          </Button>
        </DialogActions>
      </>
    );
  }

  return (
    <Dialog
      open={!!deleteId}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t`Confirm Delete`}</DialogTitle>
      {content}
    </Dialog>
  );
};

export default CheckDeleteDialog;
