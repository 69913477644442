import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import React, { useCallback, useEffect } from 'react';

import {
  fetchOrganizations,
  selectAllOrganizations,
} from 'redux/slices/organizations';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const OrganizationSelect: React.FC<{
  selectedOrganization: number;
  setSelectedOrganization: (selectedOrganization: number) => void;
}> = ({ selectedOrganization, setSelectedOrganization }) => {
  const dispatch = useAppDispatch();

  const organizations = useAppSelector(selectAllOrganizations);
  // console.log('Organization data is: ', organizations);

  const organizationRefresh = useCallback(() => {
    dispatch(fetchOrganizations());
  }, [dispatch]);
  useEffect(() => organizationRefresh(), [organizationRefresh]);

  return (
    <Select
      style={{ width: '150px', marginRight: '15px' }}
      fullWidth
      value={selectedOrganization !== -1 ? selectedOrganization : '-1'}
      onChange={({ target }) => setSelectedOrganization(Number(target.value))}
    >
      <MenuItem value="-1">{t`All`}</MenuItem>
      {organizations.map(val => (
        <MenuItem key={val.id} value={val.id}>
          {val.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default OrganizationSelect;
