/* eslint-disable */
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import useTheme from '@material-ui/core/styles/useTheme';
import ReactTable from 'react-table';
import styled from 'styled-components';
import {
  MdList,
  MdAdd,
  MdClose,
  MdRefresh,
  MdEdit,
  MdDelete,
  MdFileDownload,
} from 'react-icons/md';
import { IoMdOpen } from 'react-icons/io';
import { t } from '@lingui/macro';
import FileSaver from 'file-saver';
import Axios from 'axios';

import useBackendTableColumnsPreference from 'utilities/useBackendTableColumnsPreference';
import ComponentTitle from '../ComponentTitle';
import ColumnSettingsDialog from '../ColumnSettingsDialog';
import Loader from '../Loader';

import 'react-table/react-table.css';
import '../../../assets/css/react-table-custom.css';
import '../../../assets/css/material-ui-custom.css';
import OrganizationSelect from './OrganizationSelect';
import { DashboardContext } from 'components/shared/DashboardContext';

// // changing this will create a new settings object in the user's local storage - do this when the settings defaults change to avoid caching
// // todo: pull this version from the package json file to tie it in with the actual app version
// const settingsVersion = '3.2';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const IconsContainer = styled(Typography)`
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #999;
  position: absolute;
  right: 0;
  top: ${props => props.offset || 0};

  svg {
    cursor: pointer;
    color: ${props => props.theme.palette.text.secondary};
    &:hover {
    }
  }
`;

const makeDefaultState = columnHeaders => {
  const columnVisibility = {};
  columnHeaders.forEach(column => {
    columnVisibility[column.accessor] = {
      visible: column.default,
      default: column.default,
    };
  });
  return {
    sorted: [],
    page: 0,
    pageSize: 10,
    expanded: {},
    resized: [],
    filtered: [],
    columnVisibility,
  };
};

const CommonTable = ({
  id,
  loading,
  title,
  columnHeaders,
  columnId = 'id',
  onRefresh,
  data,
  onEdit,
  onDelete,
  onAdd,
  addTooltip = '',
  exportCsv = {},
  customActions,
  width = '100%',
  iconOffset = 0,

  // request entities is passed directly if we want to deal with pagination server-side
  fetchEntities = null,
  fetchFilters = null,
  dataTotal = 0,

  selectedOrganization = null,
  setSelectedOrganization = null,
  showPageNumber = true,
  ...rest
}) => {
  const { setOrganizationId } = useContext(DashboardContext);

  const theme = useTheme();
  const [tableOptions, setTableOptions, isLoaded] =
    useBackendTableColumnsPreference(
      `${id}-options`,
      makeDefaultState(columnHeaders),
      showPageNumber
    );
  const [columnOrder, setColumnOrder] = useBackendTableColumnsPreference(
    `${id}-order`,
    columnHeaders.map(col => col.accessor),
    showPageNumber
  );

  const [settingsOpen, setSettingsOpen] = useState(false);
  let columnVisibility = tableOptions?.columnVisibility || {};
  const [tableData, setTableData] = useState(data);

  //! SERVER SIDE PAGINATION
  // this will be spread into the react table component
  const handleFetchWithOptions = state => {
    const fetchParams = {
      skip: state.page * state.pageSize,
      take: state.pageSize || 10,
      sort:
        state.sorted && state.sorted.length
          ? state.sorted.map(field => ({
              field: field.id,
              dir: field.desc ? 'desc' : 'asc',
            }))
          : [
              {
                field: 'id',
                dir: 'desc',
              },
            ],
      // ignore any filters that are an object (suggests they are a date with start and end)
      filter:
        state.filtered &&
        state.filtered.filter(
          filter =>
            typeof filter.value !== 'object' &&
            !Number(filter.value) &&
            typeof filter.value !== 'boolean'
        ).length
          ? {
              filters: [
                ...state.filtered
                  .filter(filter => typeof filter.value !== 'object')
                  .map(field => ({
                    field: field.id,
                    operator: 'contains',
                    value: field.value,
                  })),
              ],
              logic: 'and',
            }
          : { filters: [], logic: 'and' },
    };

    // account for date fields
    state?.filtered &&
      state.filtered
        .filter(filter => typeof filter.value === 'object')
        .forEach(filter => {
          if (filter.value.startDate) {
            fetchParams.filter.filters.push({
              field: filter.id,
              operator: 'gte',
              value: filter.value?.startDate
                ? new Date(filter.value?.startDate).getTime()
                : null,
            });
          }
          if (filter.value.endDate) {
            fetchParams.filter.filters.push({
              field: filter.id,
              operator: 'lte',
              value: filter.value?.endDate
                ? new Date(filter.value?.endDate).getTime()
                : null,
            });
          }
        });
    // account for number and boolean fields
    state.filtered &&
      state.filtered
        .filter(
          filter => Number(filter.value) || typeof filter.value === 'boolean'
        )
        .forEach(filter =>
          fetchParams.filter.filters.push({
            field: filter.id,
            operator: 'eq',
            value: Number(filter.value),
          })
        );

    // add any manual filters (from props)
    if (fetchFilters)
      fetchFilters.forEach(filter => fetchParams.filter.filters.push(filter));
    fetchEntities(fetchParams);
  };
  const paginationProps = fetchEntities
    ? {
        // prop that shows we will handle sorting and pagination
        manual: true,
        pages: Math.ceil(dataTotal / tableOptions.pageSize) || 1,
      }
    : {};

  // when using Action (id, mac..) filtering show data starting on 1 page
  // Reset the page to 0 when filters change
  useEffect(() => {
    setTableOptions({
      ...tableOptions,
      page: 0,
    });
  }, [tableOptions.filtered]);

  useEffect(() => {
    if (!fetchEntities) return;
    handleFetchWithOptions(tableOptions);
  }, [
    tableOptions.sorted,
    tableOptions.filtered,
    tableOptions.page,
    tableOptions.pageSize,
  ]);

  useEffect(() => {
    if (!data || !fetchEntities || loading) return;
    let sortedData = data;
    // no need to sort if it's not a manual server table
    tableOptions.sorted.forEach(sortOption => {
      sortedData = sortedData.slice().sort((a, b) => {
        // 👇 descending order
        if (sortOption.desc)
          return a[sortOption.id] < b[sortOption.id] ? 1 : -1;
        // ☝️ ascending order
        return a[sortOption.id] > b[sortOption.id] ? 1 : -1;
      });
    });
    setTableData(sortedData);
  }, [data]);

  useEffect(() => {
    // only device unresolved alerts can't do this because it won't sort
    //// ***  BE CAREFULL: if AGAIN is assigned UnresolvedAlerts in Alerts this may be the issue  ***
    if (id !== 'alert') {
      if (!selectedOrganization || selectedOrganization === -1)
        return setTableData(data);
      setTableData(
        data.filter(entity => entity.organization___id === selectedOrganization)
      );
    }
  }, [selectedOrganization, data]);

  const resetState = useCallback(() => {
    const cleanState = makeDefaultState(columnHeaders);
    setOrganizationId(-1);
    setTableOptions(cleanState);
    if (fetchEntities) handleFetchWithOptions(resetState);
  }, [columnHeaders, columnHeaders]);

  const handlesettingsOpen = useCallback(() => setSettingsOpen(true), []);
  const handleVisibilityClose = useCallback(() => setSettingsOpen(false), []);

  const handleVisibilityChange = newVisibility =>
    setTableOptions({ ...tableOptions, columnVisibility: newVisibility });

  const handleColumnOrderChange = columnOrder => {
    setColumnOrder(columnOrder);
  };

  let visibleColumns = [];

  const parsedColumns = useMemo(() => {
    visibleColumns = columnHeaders.filter(
      column =>
        columnVisibility[column.accessor] === true ||
        columnVisibility[column.accessor]?.visible === true
    );
    const columns = [];
    columnOrder.forEach(accessor => {
      const foundColumn = visibleColumns.find(col => col.accessor === accessor);
      if (!foundColumn) return;
      columns.push(foundColumn);
    });

    // a list of components that will get passed the id
    const actions = [];
    // const resolvedAlertsActions = [];

    if (onEdit) {
      const isThisDeviceMainPage =
        id === 'deviceAed' ||
        id === 'deviceAedStatus' ||
        id === 'deviceMonitor' ||
        id === 'deviceMonitorStatus' ||
        id === 'deviceLocation';
      actions.push(({ id }) => (
        <Tooltip title={t`Edit`}>
          <IconButton
            size="small"
            aria-label={t`Edit`}
            onClick={() => onEdit(id)}
          >
            {isThisDeviceMainPage ? <IoMdOpen /> : <MdEdit />}
          </IconButton>
        </Tooltip>
      ));
    }
    if (onDelete)
      actions.push(({ id }) => (
        <Tooltip title={t`Delete`}>
          <IconButton
            size="small"
            aria-label={t`Delete`}
            onClick={() => onDelete(id)}
          >
            <MdDelete />
          </IconButton>
        </Tooltip>
      ));

    if (customActions) customActions.forEach(action => actions.push(action));

    if (actions.length) {
      columns.unshift({
        Header: t`Actions`,
        Cell: ({ original }) =>
          actions.map((Action, i) => <Action key={i} {...original} />),
        width: 60 * actions.length,
        sortable: false,
        style: {
          textAlign: 'center',
        },
      });
    }

    return columns;
  }, [
    columnHeaders,
    columnVisibility,
    columnOrder,
    customActions,
    onDelete,
    onEdit,
  ]);

  return (
    <section
      style={{
        marginBottom: '2rem',
        width: '100%',
      }}
    >
      {/* // todo: replace dynamic translation */}
      <HeaderContainer>
        {title ? <ComponentTitle>{title}</ComponentTitle> : ''}
        <IconsContainer theme={theme} offset={iconOffset}>
          {selectedOrganization && setSelectedOrganization ? (
            <OrganizationSelect
              selectedOrganization={selectedOrganization}
              setSelectedOrganization={setSelectedOrganization}
            />
          ) : null}
          {/* // todo: replace dynamic translation */}
          {onAdd ? (
            <Tooltip title={addTooltip || t`Add`}>
              <IconButton
                size="medium"
                color="secondary"
                aria-label={t`Add`}
                onClick={onAdd}
              >
                <MdAdd />
              </IconButton>
            </Tooltip>
          ) : (
            ''
          )}
          {exportCsv?.url ? (
            <Tooltip title={t`Export to CSV`}>
              <IconButton
                size="medium"
                color="secondary"
                aria-label={t`Export`}
                onClick={() => {
                  const visibleAccessors = Object.values(visibleColumns).map(
                    column => column.accessor
                  );
                  Axios.post(
                    `${
                      exportCsv.url
                    }/export/csv?columns=${visibleAccessors.join(',')}`,
                    exportCsv.filter
                  )
                    .then(res => {
                      const blob = new Blob([res.data], {
                        type: 'text/plain;charset=utf-8',
                      });
                      FileSaver.saveAs(blob, `aedt-${id}.csv`);
                    })
                    .catch(err => console.error(err));
                }}
              >
                <MdFileDownload />
              </IconButton>
            </Tooltip>
          ) : (
            ''
          )}
          <Tooltip title={t`Column settings`}>
            <IconButton
              size="medium"
              color="secondary"
              aria-label={t`Column settings`}
              onClick={handlesettingsOpen}
            >
              <MdList />
            </IconButton>
          </Tooltip>
          <Tooltip title={t`Reload data`}>
            <IconButton
              size="medium"
              color="secondary"
              aria-label={t`Reload data`}
              onClick={
                fetchEntities
                  ? () => handleFetchWithOptions(tableOptions)
                  : onRefresh
              }
            >
              <MdRefresh />
            </IconButton>
          </Tooltip>
          <Tooltip title={t`Reset table options`}>
            <IconButton
              size="medium"
              color="secondary"
              aria-label={t`Reset table options`}
              onClick={resetState}
            >
              <MdClose />
            </IconButton>
          </Tooltip>
        </IconsContainer>
      </HeaderContainer>
      <Paper>
        {isLoaded ? (
          <ReactTable
            loading={loading}
            data={tableData}
            columns={parsedColumns}
            style={{
              width,
            }}
            className="-striped"
            defaultPageSize={5}
            // controlled props
            {...tableOptions}
            // callbacks
            onSortedChange={sorted =>
              setTableOptions({ ...tableOptions, sorted })
            }
            onPageChange={page => setTableOptions({ ...tableOptions, page })}
            onPageSizeChange={pageSize =>
              setTableOptions({ ...tableOptions, pageSize })
            }
            onExpandedChange={(expanded, event) => {
              setTableOptions({ ...tableOptions, expanded });
            }}
            onResizedChange={(resized, event) => {
              setTableOptions({ ...tableOptions, resized });
            }}
            onFilteredChange={filtered =>
              setTableOptions({ ...tableOptions, filtered })
            }
            // label text translations
            previousText={t`Previous`}
            nextText={t`Next`}
            loadingText={t`Loading...`}
            noDataText={t`No rows found`}
            pageText={t`Page`}
            ofText={t`of`}
            rowsText={t`rows`}
            pageJumpText={t`jump to page`}
            rowsSelectortext={t`rows per page`}
            {...paginationProps}
            {...rest}
          />
        ) : (
          <Loader />
        )}
      </Paper>
      <ColumnSettingsDialog
        open={settingsOpen}
        onClose={handleVisibilityClose}
        columnOrder={columnOrder}
        columnHeaders={columnHeaders}
        onColumnOrderChange={handleColumnOrderChange}
        onVisibilityChange={handleVisibilityChange}
        columnVisibility={columnVisibility}
      />
    </section>
  );
};

export default CommonTable;
