import React from 'react';
import ErrorPage from 'components/pages/Error';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    // this.error = { message: '', stack: '' };
    // this.info = { componentStack: '' };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch = (error, info) => {
    this.setState({ error, info });
    console.error('New error is: ', error, info);
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPage />;
    }
    return this.props.children;
  }
}
