import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FiSettings } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { modifyAccount } from 'redux/slices/accounts';
import styled from 'styled-components';
import pjson from '../../../../package.json';
import { logoutUser } from '../../../redux/slices/auth';

const InfoPanel = styled.div`
  font-size: 0.8rem;
  width: 10rem;
  padding: 0.8rem 1rem;
  margin-bottom: 0.5rem;
  line-height: 1.5;
  /* text-align: center; */
  /* colors for this section are just inversed */
  border-bottom: 1px solid ${props => props.theme.palette.divider};
  background: ${props => props.theme.palette.text.primary};
  color: ${props => props.theme.palette.background.default};
  margin-top: -8px;

  div {
    margin: 0.2rem 0;
  }
  .app-version {
    font-size: 0.6rem;
  }
`;

const SectionWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.palette.divider};
  padding: 0.5rem 0;
  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
`;

const UserMenu = () => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const currentUser = useAppSelector(state => state.auth.current);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { i18n } = useLingui();

  const handleClick = e => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleLogout = async () => {
    try {
      await dispatch(logoutUser()).unwrap();
      toast.success(t`Successfully logged out`);
      history.push('/');
    } catch (err) {
      console.log(`error is: ${err}`);
      toast.error(t`There was an error logging out`);
    }
  };

  // example of curried function
  const handleChangeLanguage = lang => async () => {
    const accountResource = { ...currentUser, languageCode: lang };
    delete accountResource.timeCreated;
    try {
      await dispatch(modifyAccount(accountResource)).unwrap();
      toast.success(t`Account language was successfully changed`);

      i18n.activate(lang);
      window.localStorage.setItem('lang', lang);
      handleClose();
    } catch (err) {
      console.log('Error while updating language: ', err);
      toast.error(t`There was a problem changing the account language`);
    }
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <FiSettings style={{ color: theme.palette.text.secondary }} />
      </IconButton>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <InfoPanel theme={theme}>
          <div>
            {t`Logged in as`}{' '}
            <strong>{currentUser.name}{' '}{currentUser.last___name}</strong>
          </div>
          <div className="app-version">
            {pjson.name} v{pjson.version}
          </div>
        </InfoPanel>
        <SectionWrapper theme={theme}>
          <MenuItem
            onClick={handleChangeLanguage('en')}
            selected={i18n.locale === 'en'}
          >
            English
          </MenuItem>
          <MenuItem
            onClick={handleChangeLanguage('de')}
            selected={i18n.locale === 'de'}
          >
            Deutsch
          </MenuItem>
          <MenuItem
            onClick={handleChangeLanguage('fr')}
            selected={i18n.locale === 'fr'}
            disabled
          >
            Français
          </MenuItem>
          <MenuItem
            onClick={handleChangeLanguage('it')}
            selected={i18n.locale === 'it'}
            disabled
          >
            Italiano
          </MenuItem>
        </SectionWrapper>
        <SectionWrapper theme={theme}>
          <MenuItem
            onClick={() => {
              history.push('/user/edit');
              handleClose();
            }}
          >
            {t`Account`}
          </MenuItem>
          <MenuItem onClick={handleLogout}>{t`Logout`}</MenuItem>
        </SectionWrapper>
      </Menu>
    </>
  );
};

UserMenu.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default UserMenu;
