import React, { Ref, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import { NavLink } from 'react-router-dom';
import { t } from '@lingui/macro';

import { useAppSelector } from 'redux/hooks';
import menuItems from 'config/menuItems';
import { Container, StyledList } from './Sidebar.styled';

// eslint-disable-next-line react/display-name
const AdapterNavLink = React.forwardRef(
  (props: any, ref: Ref<HTMLAnchorElement>) => (
    <NavLink innerRef={ref} {...props} to={props.to || ''} />
  )
);

interface SidebarProps {
  open: boolean;
  toggleOpen: () => void;
  midScreen: boolean;
  location: string;
}

const Sidebar: React.FC<SidebarProps> = ({
  open,
  toggleOpen,
  midScreen,
  location,
}) => {
  const theme = useTheme();
  const currentUser = useAppSelector(state => state.auth.current);

  const [activeMenuItemLocation, setActiveMenuItemLocation] =
    useState(location);

  return (
    <Drawer
      variant={midScreen ? 'temporary' : 'permanent'}
      open={open}
      onClose={toggleOpen}
    >
      <Container>
        <StyledList theme={theme}>
          <ListItem className="listItem" disableGutters>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*// @ts-ignore*/}
            <Box className="listBox" />
          </ListItem>
          {menuItems().map(item => {
            if (
              item.restrict === 'SUPER' &&
              currentUser?.role !== 'SUPER_ADMIN'
            )
              return null;
            return (
              <ListItem
                className="listItem"
                activeClassName={
                  activeMenuItemLocation === item.href ? 'listItemActive' : ''
                }
                disableGutters
                component={AdapterNavLink}
                to={item.href}
                key={item.title}
                onClick={() => {
                  setActiveMenuItemLocation(item.href);
                  toggleOpen();
                }}
              >
                <Button className="listBox">
                  {item.icon && <span className="listIcon">{item.icon}</span>}
                  <Typography
                    variant="button"
                    color="inherit"
                    className="listItemText"
                  >
                    {item.title}
                  </Typography>
                </Button>
              </ListItem>
            );
          })}
        </StyledList>
      </Container>
    </Drawer>
  );
};

export default Sidebar;
