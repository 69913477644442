import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

const locale = 'en-GB';
const CustomTextField = () => <TextField style={{ width: 80 }} />;

const DateRangePicker = ({
  onChange,
  popperPlacement,
  labelFrom = 'From...',
  labelTo = 'To...',
  value = '',
}) => {
  const startDate = value.startDate ? new Date(value.startDate) : '';
  const endDate = value.endDate ? new Date(value.endDate) : '';

  return (
    <div>
      <DatePicker
        //! this should actually be the state from this component?
        value={startDate || ''}
        dateFormat="dd.MM.yyyy"
        locale={locale}
        customInput={CustomTextField(labelFrom)}
        selected={startDate}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        onChange={val => onChange({ ...value, startDate: val })}
        placeholderText={t`From...`}
        popperPlacement={popperPlacement}
        popperModifiers={{
          preventOverflow: {
            enable: !popperPlacement,
          },
        }}
      />
      –
      <DatePicker
        value={endDate || ''}
        dateFormat="dd.MM.yyyy"
        locale={locale}
        customInput={CustomTextField(labelTo)}
        selected={endDate}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        onChange={val => onChange({ ...value, endDate: val })}
        minDate={startDate}
        placeholderText={t`To...`}
        popperPlacement={popperPlacement}
        popperModifiers={{
          preventOverflow: {
            enable: !popperPlacement,
          },
        }}
      />
    </div>
  );
};

DateRangePicker.propTypes = {
  onChange: PropTypes.func,
  popperPlacement: PropTypes.string,
  labelFrom: PropTypes.string,
  labelTo: PropTypes.string,
  value: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }).isRequired,
  }),
};

export default DateRangePicker;
