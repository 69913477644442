/* eslint no-param-reassign: "off" */
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import httpClient from 'utilities/httpClient';

export type AlertCode =
  | 'AED_BATTERY_END_OF_LIFE'
  | 'AED_BATTERY_LOW'
  | 'AED_ELECTRODE_END_OF_LIFE'
  | 'DEVICE_LOW_BATTERY'
  | 'DEVICE_MOVED'
  | 'DEVICE_LAST_SEEN'
  | 'DEVICE_READINESS'
  | 'DEVICE_TEMPERATURE'
  | 'DSF_FILE_ERROR_LIST'
  | 'AED_SELF_REPORT_MISSED';

export interface AlertType {
  alertCode: AlertCode;
  alertType: 'DAILY' | 'INSTANT';
  attribute: string;
  attributeMax: number;
  attributeMin: number;
  attributeTimeout: number;
  deviceType___id: number;
  id: number;
  name: string;
  timeCreated: number;
  notificationGroup: string;
  severityLevel: 'ORANGE' | 'RED';
  checkbox: boolean;
}

const alertTypesAdapter = createEntityAdapter<AlertType>();

export const fetchAlertTypes = createAsyncThunk(
  'alertTypes/fetchAll',
  async () => {
    const response = await httpClient.post('/service/rest/alert-type');
    return response.data.data;
  }
);

export const modifyAlertType = createAsyncThunk(
  'alertTypes/modifyAlertType',
  async alertType => {
    const response = await httpClient.post(
      '/service/rest/alert-type/modifyAlert',
      alertType
    );
    return response.data;
  }
);

const alertTypesSlice = createSlice({
  name: 'alertTypes',
  initialState: alertTypesAdapter.getInitialState({
    // 'idle' | 'loading' | 'error' | 'succeeded' | 'updating'
    status: 'idle',
    error: {},
  }),
  reducers: {},
  extraReducers(builder) {
    builder
      // fetch all
      .addCase(fetchAlertTypes.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchAlertTypes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        alertTypesAdapter.setAll(state, action.payload);
      })
      .addCase(fetchAlertTypes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      })
      // modify one alert type
      .addCase(modifyAlertType.pending, state => {
        state.status = 'updating';
      })
      .addCase(modifyAlertType.fulfilled, (state, action) => {
        state.status = 'succeeded';
        alertTypesAdapter.upsertOne(state, action.payload);
      })
      .addCase(modifyAlertType.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

export default alertTypesSlice.reducer;

// memoised selectors
export const { selectAll: selectAllAlertTypes } =
  alertTypesAdapter.getSelectors<RootState>(state => state.alertTypes);
