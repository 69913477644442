import { createTheme } from '@material-ui/core';

// TODO: define multiple themes here the user can choose from settings

const warmPink = {
  main: '#ea746e',
  contrastText: '#fff',
};
const aquamarine = {
  main: '#36d4a9',
  light: '#36d4a944',
  contrastText: '#fff',
};

// check out the default theme here: https://material-ui.com/customization/default-theme/
const theme = createTheme({
  palette: {
    primary: aquamarine,
    secondary: warmPink,
    white: '#fff',
    action: {
      hover: aquamarine.light,
    },
  },
  typography: {
    fontFamily: [
      'Barlow',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiButton: {
      root: {
        width: '155px',
        borderRadius: 0,
        minHeight: '36px',
        padding: 0,
        fontSize: '13px',
      },
      contained: {
        backgroundColor: '#333b4b',
        color: '#fff',
      },
    },
    MuiTab: {
      root: {
        '&.Mui-selected': {
          backgroundColor: aquamarine.light,
        },
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid #bebebe',
        },
        '&.Mui-disabled:before': {
          borderBottom: '1px solid #bebebe',
        },
      },
    },
    MuiLinearProgress: {
      root: {
        height: '5px',
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: '.9rem',
        fontWeight: 500,
      },
      secondary: {
        fontSize: '.75rem',
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
    MuiBreadcrumbs: {
      separator: {
        fontSize: '1.3rem',
      },
    },
  },
});

export default theme;
