import { useLingui } from '@lingui/react';
import { Button } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { lazy, Suspense, useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import './assets/css/react-toastify-custom.css';
import Loader from './components/shared/Loader';
import { checkAuth } from './redux/slices/auth';
import Routes from './Routes';
import theme from './theme';

const Login = lazy(() => import('./components/pages/Login'));

// create react query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const ToastClose = ({ closeToast }) => (
  <Button
    onClick={closeToast}
    style={{
      fontSize: '1.8rem',
      color: 'rgba(255,255,255, 0.7)',
      width: 70,
      marginLeft: 20,
    }}
  >
    <MdClose />
  </Button>
);
ToastClose.propTypes = {
  closeToast: PropTypes.func,
};

function App() {
  const dispatch = useAppDispatch();
  const { i18n } = useLingui();
  const currentUser = useAppSelector(state => state.auth.current);

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      i18n.activate(currentUser.languageCode.toLowerCase());
    }
  }, [currentUser]);

  return (
    <QueryClientProvider client={queryClient}>
      <StylesProvider injectFirst>
        {/* injectFirst prop allows styled components to inject after (override) MUI styles */}
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ToastContainer
            position="bottom-right"
            closeButton={<ToastClose />}
          />
          {currentUser ? (
            <Router>
              <Routes />
            </Router>
          ) : (
            <Suspense fallback={<Loader />}>
              <Login />
            </Suspense>
          )}
        </ThemeProvider>
      </StylesProvider>
    </QueryClientProvider>
  );
}

export default App;
