import { configureStore } from '@reduxjs/toolkit';

import auth from './slices/auth';
import accounts from './slices/accounts';
import devices from './slices/devices';
import logs from './slices/logs';
import alerts from './slices/alerts';
import alertTypes from './slices/alertTypes';
import network from './slices/networkConfigs';
import organizations from './slices/organizations';
import rfids from './slices/rfids';
import languages from './slices/languages';
import questionsAndAnswers from './slices/questionsAndAnswers';
import locations from './slices/locations';
import positions from './slices/positions';

const store = configureStore({
  reducer: {
    auth,
    devices,
    logs,
    accounts,
    alerts,
    alertTypes,
    network,
    organizations,
    rfids,
    languages,
    questionsAndAnswers,
    locations,
    positions,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
