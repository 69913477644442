/* eslint no-param-reassign: off */
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import httpClient from 'utilities/httpClient';

export interface Organization {
  city: string;
  country: string;
  houseNumber: string;
  id: number;
  name: string;
  parent___id: number;
  postcode: string;
  street: string;
  timeCreated?: number;
}

const organizationsAdapter = createEntityAdapter<Organization>({
  sortComparer: (a, b) => (a.id > b.id ? -1 : 1),
});

export const fetchOrganizations = createAsyncThunk(
  'organization/fetchAll',
  async () => {
    const response = await httpClient.get('/service/rest/organization');
    return response.data;
  }
);

export const modifyOrganization = createAsyncThunk(
  'organization/modifyOneOrganization',
  async organization => {
    const response = await httpClient.post(
      '/service/rest/organization/modify',
      organization
    );
    return response.data;
  }
);

export const modifyListOrganizations = createAsyncThunk(
  'organization/modifyListOrganizations',
  async (organizations: Organization[]) => {
    const response = await httpClient.post(
      '/service/rest/organization/modifyOrganizationList',
      organizations
    );

    return response.data;
  }
);

export const deleteOrganization = createAsyncThunk(
  'organization/deleteOneOrganization',
  async (id: number) => {
    await httpClient.get(`/service/rest/organization/deleteOrganization/${id}`);
    return Number(id);
  }
);

const organizationsSlice = createSlice({
  name: 'organization',
  initialState: organizationsAdapter.getInitialState({
    // 'idle' | 'loading' | 'error' | 'succeeded' | 'updating' | 'deleting'
    status: 'idle',
    error: {},
  }),
  reducers: {},
  extraReducers(builder) {
    builder
      // fetch all
      .addCase(fetchOrganizations.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchOrganizations.fulfilled, (state, action) => {
        state.status = 'succeeded';
        organizationsAdapter.setAll(state, action.payload);
      })
      .addCase(fetchOrganizations.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      })
      // create / modify one
      .addCase(modifyOrganization.pending, state => {
        state.status = 'updating';
      })
      .addCase(modifyOrganization.fulfilled, (state, action) => {
        state.status = 'succeeded';
        organizationsAdapter.upsertOne(state, action.payload);
      })
      // modify list organizations
      .addCase(modifyListOrganizations.pending, state => {
        state.status = 'updating';
      })
      .addCase(modifyListOrganizations.fulfilled, (state, action) => {
        state.status = 'succeeded';
        organizationsAdapter.upsertMany(state, action.payload);
      })
      .addCase(modifyListOrganizations.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      })
      // deleting
      .addCase(deleteOrganization.pending, state => {
        state.status = 'deleting';
      })
      .addCase(deleteOrganization.fulfilled, (state, action) => {
        state.status = 'succeeded';
        organizationsAdapter.removeOne(state, action.payload);
        //console.log('Organization slice is: ', state.entities);
      })
      .addCase(deleteOrganization.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

export default organizationsSlice.reducer;

// memoised selectors
export const {
  selectAll: selectAllOrganizations,
  selectById: selectOrganizationById,
} = organizationsAdapter.getSelectors<RootState>(state => state.organizations);
