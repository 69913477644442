/* eslint no-param-reassign: off */
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import httpClient from 'utilities/httpClient';

export interface Rfid {
  id: number;
  uuid: string;
  organization_id: number;
  organizationName: string;
  timeCreated?: number;
  positionId: number;
  positionDescription: string;
  locationDescription: string;
  locationId: number;
  positionStorageTypeId: number;
  trxMacAddress: string;
  trxId: number;
  aedSn: string;
  lastEncountered: number;
}

const rfidsAdapter = createEntityAdapter<Rfid>({
  sortComparer: (a, b) => (a.id > b.id ? -1 : 1),
});

export const fetchRfids = createAsyncThunk('rfid/fetchAll', async () => {
  const response = await httpClient.get('/service/rest/position/rfid');
  return response.data;
});

export const modifyRfid = createAsyncThunk('rfid/modifyOneRfid', async rfid => {
  //console.log('RFID upload data are: ', rfid);
  const response = await httpClient.post(
    '/service/rest/position/rfid/modify',
    rfid
  );
  return response.data;
});

// export const modifyListRfids = createAsyncThunk(
//   'rfid/modifyRfidList',
//   async (rfids: Rfid[]) => {
//     const response = await httpClient.post(
//       '/service/rest/position/rfid/modifyRfidList',
//       rfids
//     );

//     return response.data;
//   }
// );

export const deleteRfid = createAsyncThunk(
  'rfid/deleteOneRfid',
  async (id: number) => {
    await httpClient.get(`/service/rest/position/deleteRfid/${id}`);
    return Number(id);
  }
);

const rfidSlice = createSlice({
  name: 'rfid',
  initialState: rfidsAdapter.getInitialState({
    // 'idle' | 'loading' | 'error' | 'succeeded' | 'updating' | 'deleting'
    status: 'idle',
    error: {},
  }),
  reducers: {},
  extraReducers(builder) {
    builder
      // fetch all
      .addCase(fetchRfids.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchRfids.fulfilled, (state, action) => {
        state.status = 'succeeded';
        rfidsAdapter.setAll(state, action.payload);
      })
      .addCase(fetchRfids.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      })
      // create / modify one
      .addCase(modifyRfid.pending, state => {
        state.status = 'updating';
      })
      .addCase(modifyRfid.fulfilled, (state, action) => {
        state.status = 'succeeded';
        rfidsAdapter.upsertOne(state, action.payload);
      })
      .addCase(modifyRfid.rejected, state => {
        state.status = 'failed';
      })
      // modify list rfids
      // .addCase(modifyListRfids.pending, state => {
      //   state.status = 'updating';
      // })
      // .addCase(modifyListRfids.fulfilled, (state, action) => {
      //   state.status = 'succeeded';
      //   rfidsAdapter.upsertMany(state, action.payload);
      // })
      // .addCase(modifyListRfids.rejected, (state, action) => {
      //   state.status = 'failed';
      //   state.error = action.error;
      // })
      // deleting
      .addCase(deleteRfid.pending, state => {
        state.status = 'deleting';
      })
      .addCase(deleteRfid.fulfilled, (state, action) => {
        state.status = 'succeeded';
        rfidsAdapter.removeOne(state, action.payload);
        //console.log('Rfid slice is: ', state.entities);
      })
      .addCase(deleteRfid.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

export default rfidSlice.reducer;

// memoised selectors
export const { selectAll: selectAllRfids, selectById: selectRfidById } =
  rfidsAdapter.getSelectors<RootState>(state => state.rfids);
