/* eslint no-param-reassign: "off" */
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { RequestParams } from 'api/types';
import { RootState } from 'redux/store';
import httpClient from 'utilities/httpClient';
import { AlertCode } from './alertTypes';

export interface Alert {
  alertAttributes: string;
  alertName: string;
  alertSolutionResponse: {
    id: number;
    resolvedBy: string;
    solution: string;
    timeCreated: number;
  };
  alertType: AlertCode;
  deviceId: number;
  deviceName: string;
  doorOpen: boolean;
  id: number;
  isRead: boolean;
  latitude: number;
  longitude: number;
  timeCreated: number;
}

export interface ResolveAlert {
  alertId?: number;
  solution: string;
  account___id?: number;
  device___id?: number;
}

const alertsAdapter = createEntityAdapter<Alert>({
  sortComparer: (a, b) => (a.id > b.id ? -1 : 1),
});

export const fetchUnresolvedAlerts = createAsyncThunk(
  'alerts/fetchAll',
  async (params: RequestParams) => {
    const response = await httpClient.post('/web/alerts', {
      ...params,
      sort: params.sort,
      filter: {
        filters: [
          {
            field: 'alertSolutionResponse',
            operator: 'isnull',
            value: true,
          },
          ...(params.filter?.filters || []),
        ],
        logic: 'and',
      },
    });
    return response;
  }
);

export const fetchAllResolvedAlerts = createAsyncThunk(
  'alerts/fetchAllResolvedAlerts',
  async (params: RequestParams) => {
    const response = await httpClient.post('/web/alerts', {
      ...params,
      sort: params.sort,
      filter: {
        filters: [
          {
            field: 'alertSolutionResponse',
            operator: 'isnotnull',
            value: true,
          },
          ...(params.filter?.filters || []),
        ],
        logic: 'and',
      },
    });
    return response;
  }
);

export const resolveAlert = createAsyncThunk(
  'alerts/resolveAlert',
  async (resolvedAlertData: ResolveAlert) => {
    const alertId = resolvedAlertData.alertId;
    delete resolvedAlertData.alertId;
    await httpClient.post(
      `/service/rest/device/resolved-alerts-events/${alertId}/createEventSolution`,
      resolvedAlertData
    );
    return resolvedAlertData;
  }
);

export const fetchDeviceLocationAlerts = createAsyncThunk(
  'alerts/fetchDeviceLocationAlerts',
  async (currentDeviceId: number) => {
    const response = await httpClient.post('/web/alerts', {
      sort: [
        {
          field: 'id',
          dir: 'desc',
        },
      ],
      filter: {
        filters: [
          {
            field: 'deviceId',
            operator: 'eq',
            value: Number(currentDeviceId),
          },
          {
            field: 'alertType',
            operator: 'eq',
            value: 'DEVICE_MOVED',
          },
        ],
        logic: 'and',
      },
      take: 100,
    });
    return response.data.data;
  }
);

// It is needed inside of layout/index.js file
export const fetchUnreadAlertsCount = createAsyncThunk(
  'alerts/fetchUnreadAlertsCount',
  async () => {
    const response = await httpClient.get('/web/alerts/unread/count');
    return response.data;
  }
);

// there is no need for extra reducers and addCasse, because we are just sending body to backend
export const setAlertsTimestamp = createAsyncThunk(
  'alerts/setAlertsTimestamp',
  async () => {
    await httpClient.post('/web/alerts/lastViewTime', {
      lastViewTime: Date.now(),
    });
  }
);

// It is needed inside of dashboard/index.js file
export const fetchUnreadAlerts = createAsyncThunk(
  'alerts/fetchUnreadAlerts',
  async () => {
    const response = await httpClient.post('/web/alerts', {
      sort: [
        {
          field: 'id',
          dir: 'desc',
        },
      ],
      filter: {
        filters: [
          {
            field: 'isRead',
            operator: 'eq',
            value: false,
          },
        ],
        logic: 'and',
      },
      take: 6,
    });
    return response.data;
  }
);

const alertsSlice = createSlice({
  name: 'alerts',
  initialState: alertsAdapter.getInitialState({
    // 'idle' | 'loading' | 'error' | 'succeeded'
    status: false,
    error: {},

    resolvedAlerts: {
      fetching: false,
      data: [],
      total: 0,
    },

    total: 0,

    currentDeviceLocationAlerts: {
      fetching: false,
      data: [],
    },
    unreadAlertsCount: 0,

    unreadAlerts: [],
  }),
  reducers: {},
  extraReducers(builder) {
    builder
      // fetch all alerts
      .addCase(fetchUnresolvedAlerts.pending, state => {
        state.status = true;
      })
      .addCase(fetchUnresolvedAlerts.fulfilled, (state, action) => {
        state.status = false;
        state.total = action.payload.data.total;

        alertsAdapter.setAll(state, action.payload.data.data);
      })
      .addCase(fetchAllResolvedAlerts.pending, state => {
        state.resolvedAlerts.fetching = true;
      })
      .addCase(fetchAllResolvedAlerts.fulfilled, (state, action) => {
        state.resolvedAlerts.fetching = false;
        state.resolvedAlerts.total = action.payload.data.total;

        state.resolvedAlerts.data = action.payload.data.data;
      })
      .addCase(fetchAllResolvedAlerts.rejected, state => {
        state.resolvedAlerts.fetching = false;
      })
      // resolve alert
      .addCase(resolveAlert.pending, state => {
        state.status = true;
      })
      .addCase(resolveAlert.fulfilled, (state, action) => {
        // take alert and put it in the resolved alerts list
        state.status = false;
        // alertsAdapter.removeOne(state, action.payload.device___id);
        // resolved alerts are refetched
      })
      .addCase(resolveAlert.rejected, (state, action) => {
        state.status = false;
        state.error = action.error;
      })
      // fetch device location alerts
      .addCase(fetchDeviceLocationAlerts.pending, state => {
        state.currentDeviceLocationAlerts.fetching = true;
        state.currentDeviceLocationAlerts.data = [];
      })
      .addCase(fetchDeviceLocationAlerts.fulfilled, (state, action) => {
        state.currentDeviceLocationAlerts.fetching = false;
        state.currentDeviceLocationAlerts.data = action.payload;
      })
      .addCase(fetchDeviceLocationAlerts.rejected, state => {
        state.currentDeviceLocationAlerts.fetching = false;
        state.currentDeviceLocationAlerts.data = [];
      })
      // fetch Unread Alerts Count
      .addCase(fetchUnreadAlertsCount.fulfilled, (state, action) => {
        state.unreadAlertsCount = action.payload.total;
      })
      // fetch Unread Alerts
      .addCase(fetchUnreadAlerts.fulfilled, (state, action) => {
        state.unreadAlerts = action.payload.data;
      });
  },
});

export default alertsSlice.reducer;

// memoised selectors
export const { selectAll: selectAllAlerts } =
  alertsAdapter.getSelectors<RootState>(state => state.alerts);