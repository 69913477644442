/* eslint no-param-reassign: "off" */
import {
  createSlice,
  createAsyncThunk,
  SerializedError,
} from '@reduxjs/toolkit';
import httpClient from 'utilities/httpClient';
import { Account } from './accounts';

export type Credentials = {
  email: string;
  password: string;
};

type AuthState = {
  current: Account | null;
  status: 'idle' | 'loading' | 'failed' | 'succeeded';
  error: SerializedError;
};
const initialState: AuthState = {
  current: null,
  status: 'idle',
  error: {},
};

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async ({ email, password }: Credentials) => {
    const response = await httpClient.post('/login', {
      email,
      password,
    });
    return response.data;
  }
);

export const logoutUser = createAsyncThunk('auth/logoutUser', async () => {
  await httpClient.get('/logout');
});

export const checkAuth = createAsyncThunk('auth/checkAuth', async () => {
  const response = await httpClient.get('/check-auth');
  return response.data;
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // login user
      .addCase(loginUser.pending, state => {
        state.status = 'loading';
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.current = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      })
      // logoutUser user
      .addCase(logoutUser.fulfilled, state => {
        state.status = 'succeeded';
        state.current = null;
      })
      // check auth
      .addCase(checkAuth.pending, state => {
        state.status = 'loading';
      })
      .addCase(checkAuth.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.current = action.payload;
      })
      .addCase(checkAuth.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

export default authSlice.reducer;
