import { createContext, useState } from 'react';

type AlertsContextType = {
  alertUnresolvedId: number;
  setAlertUnresolvedId: (alertId: number) => void;
  alertResolvedId: number;
  setAlertResolvedId: (alertId: number) => void;
};

export const AlertsContext = createContext({} as AlertsContextType);

export const AlertsProvider: React.FC = ({ children }) => {
  const [alertUnresolvedId, setAlertUnresolvedId] = useState(0);
  const [alertResolvedId, setAlertResolvedId] = useState(0);

  return (
    <AlertsContext.Provider
      value={{
        alertUnresolvedId,
        setAlertUnresolvedId,
        alertResolvedId,
        setAlertResolvedId,
      }}
    >
      {children}
    </AlertsContext.Provider>
  );
};
