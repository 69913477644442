/* eslint no-param-reassign: "off" */
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import httpClient from 'utilities/httpClient';

export interface Language {
  code: string;
  id: number;
  name: string;
  timeCreated: number;
}

const languagesAdapter = createEntityAdapter<Language>({
  sortComparer: (a, b) => (a.id > b.id ? -1 : 1),
});

export const fetchLanguages = createAsyncThunk(
  'languages/fetchAll',
  async take => {
    const response = await httpClient.post('/service/rest/language', take);
    return response.data.data;
  }
);

const languagesSlice = createSlice({
  name: 'languages',
  initialState: languagesAdapter.getInitialState({
    // 'idle' | 'loading' | 'error' | 'succeeded'
    status: 'idle',
    error: {},
  }),
  reducers: {},
  extraReducers(builder) {
    builder
      // fetch all
      .addCase(fetchLanguages.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchLanguages.fulfilled, (state, action) => {
        state.status = 'succeeded';
        languagesAdapter.setAll(state, action.payload);
      })
      .addCase(fetchLanguages.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

export default languagesSlice.reducer;

// memoised selectors
export const { selectAll: selectAllLanguages } =
  languagesAdapter.getSelectors<RootState>(state => state.languages);
