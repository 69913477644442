import { Box, Button } from '@material-ui/core';
import Loader from 'components/shared/Loader';
import { useEffect, useRef, useState } from 'react';
import { t } from '@lingui/macro';
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import { useAppSelector } from 'redux/hooks';
import { Organization } from 'redux/slices/organizations';
import { TreeItemExtended } from './OrganizationDetails/Positions/PositionTree';

interface OrganizationTreeProps {
  organizations: Organization[];
  status: string;
  onSave: (organizations: Organization[]) => void;
}

const orgToTree = (organizations: Organization[]): TreeItemExtended[] => {
  const treeOrganizations: TreeItemExtended[] = [];

  const assignChildren = (
    orgWithoutChildren: TreeItemExtended
  ): TreeItemExtended => {
    // inside here we want to find the children of the current organization
    const orgWithChildren = { ...orgWithoutChildren };
    // find children of current org
    const children = organizations
      .filter(org => org.parent___id === orgWithoutChildren.id)
      .map(org => assignChildren(parseOrgToTree(org)));
    orgWithChildren.children = children;
    return orgWithChildren;
  };

  const parseOrgToTree = (org: Organization): TreeItemExtended => {
    let fullAddress = '';
    if (
      org.city &&
      org.street &&
      org.houseNumber &&
      org.country &&
      org.postcode
    ) {
      fullAddress = `${org.city}, ${org.street}, ${org.houseNumber}, ${org.country}, ${org.postcode}`;
      if (fullAddress.length > 30) {
        fullAddress = fullAddress.substring(0, 30).concat(' ...');
      }
    }

    return {
      id: org.id,
      title: org.name,
      subtitle: fullAddress,
      parent___id: org.parent___id,
      children: [],
    };
  };

  const findFirstOrganization = (
    organizations: Organization[]
  ): Organization => {
    let firstOrg = organizations.find(org => !org.parent___id) as Organization;
    if (firstOrg !== undefined) {
      return firstOrg;
    }
    organizations.forEach(org => {
      const isThisParentOrg = organizations.find(
        orgId => orgId.id === org.parent___id
      );
      if (isThisParentOrg === undefined) {
        firstOrg = org;
      }
    });
    return firstOrg;
  };

  let firstOrg = findFirstOrganization(organizations);
  if (!firstOrg) {
    const parentIds = organizations.map(org => org.parent___id);
    firstOrg = organizations.find(
      org => !parentIds.includes(org.id)
    ) as Organization;
  }
  if (!firstOrg) return [];
  const parsedOrg = parseOrgToTree(firstOrg);
  const orgWithChildren = assignChildren(parsedOrg);
  treeOrganizations.push(orgWithChildren);

  return treeOrganizations;
};

const treeToOrg = (
  treeData: TreeItemExtended[],
  organizations: Organization[]
): Organization[] => {
  const organizationsList: Organization[] = [];

  const flattenChildren = (
    treeItems: TreeItemExtended[],
    parentOrgId: number
  ) =>
    treeItems.map((org: TreeItemExtended) => {
      const correctOrganization: Organization | undefined = organizations.find(
        value => value.id === org.id
      );
      organizationsList.push(
        parseTreeToOrg(org, parentOrgId, correctOrganization)
      );
      flattenChildren(
        org.children as TreeItemExtended[],
        Number(org.id) as number
      );
      return org;
    });

  const parseTreeToOrg = (
    tree: TreeItemExtended,
    parentOrgId: number,
    correctOrganization: Organization | undefined
  ): Organization => {
    return {
      // address: tree.subtitle as string,
      city: correctOrganization?.city as string,
      country: correctOrganization?.country as string,
      houseNumber: correctOrganization?.houseNumber as string,
      id: tree.id,
      name: tree.title as string,
      parent___id: parentOrgId === -1 ? tree.parent___id : parentOrgId,
      postcode: correctOrganization?.postcode as string,
      street: correctOrganization?.street as string,
    };
  };

  flattenChildren(treeData, -1);
  return organizationsList;
};

const OrganizationTree: React.FC<OrganizationTreeProps> = ({
  organizations,
  status,
  onSave,
}) => {
  const [treeNodeLevel, setTreeNodeLevel] = useState<number>(0);

  const initialTreeData = useRef<TreeItemExtended[]>(orgToTree(organizations));

  const [treeData, setTreeData] = useState<TreeItemExtended[]>(
    orgToTree(organizations)
  );

  useEffect(() => {
    setTreeData(orgToTree(organizations));
  }, [organizations]);

  const currentUser = useAppSelector(state => state.auth.current);
  const hasUserRights =
    currentUser?.role === 'SUPER_ADMIN' || currentUser?.role === 'ADMIN';

  let organizationButtons;
  if (hasUserRights) {
    organizationButtons = (
      <>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/*// @ts-ignore*/}
        <Box display="flex" justifyContent="flex-start" mt={4}>
          <Button
            variant="outlined"
            style={{ width: '170px', marginRight: '1rem' }}
            color="secondary"
            onClick={e => {
              setTreeData(initialTreeData.current);
            }}
          >
            {t`Reset`}
          </Button>
          <Button
            variant="outlined"
            style={{ width: '170px', marginRight: '1rem' }}
            color="primary"
            disabled={status === 'updating'}
            onClick={e => {
              const treeBackendData = treeToOrg(treeData, organizations);
              onSave(treeBackendData);
            }}
          >
            {status === 'updating' ? <Loader button /> : t`Save Changes`}
          </Button>
        </Box>
      </>
    );
  }

  if (status === 'loading') return <Loader />;

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*// @ts-ignore*/}
      <SortableTree
        isVirtualized={false}
        rowHeight={80}
        // allow only one top parent node or organization
        canDrop={({ nextParent, nextPath }) =>
          (!nextParent || !nextParent.noChildren) && nextPath.length > 1
        }
        treeData={treeData}
        onVisibilityToggle={({ expanded }) => {
          if (treeNodeLevel >= 0) {
            if (expanded) {
              const increaseValue = treeNodeLevel + 1;
              setTreeNodeLevel(increaseValue);
            } else {
              const decreaseValue = treeNodeLevel - 1;
              setTreeNodeLevel(decreaseValue);
            }
          }
        }}
        onChange={treeValues => {
          setTreeData(treeValues);
        }}
        generateNodeProps={({ node }) => ({
          title: (
            <>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/*// @ts-ignore*/}
              <Box
                style={{
                  textTransform: 'uppercase',
                  width: `calc(80vh - ( ${treeNodeLevel} * 100px ) )`,
                }}
                fontSize="1rem"
                fontWeight="300"
                fontFamily="'Barlow', sans-serif"
              >
                {node.title as string}
              </Box>
            </>
          ),
          subtitle: (
            <>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/*// @ts-ignore*/}
              <Box
                style={{
                  fontSize: '0.8rem',
                  fontStyle: 'italic',
                  marginTop: '0.6rem',
                  width: `calc(80vh - ( ${treeNodeLevel} * 100px ) )`,
                }}
              >
                {node.subtitle as string}
              </Box>
            </>
          ),
        })}
      />
      {organizationButtons}
    </>
  );
};

export default OrganizationTree;
