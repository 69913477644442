/* eslint no-param-reassign: "off" */
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import httpClient from 'utilities/httpClient';

export type ModemRadioAccess = 'GSM' | 'NB1' | 'CATM1';

export interface NetworkConfig {
  apnPass: string;
  apnUrl: string;
  apnUser: string;
  band: number;
  id: number;
  modemRadioAccess: ModemRadioAccess;
  name: string;
  organization___id: number;
  organization___name?: string;
  plmn: string;
  smsc: string;
}

const networkConfigsAdapter = createEntityAdapter<NetworkConfig>({
  sortComparer: (a, b) => (a.id > b.id ? -1 : 1),
});

export const fetchNetworkConfigs = createAsyncThunk(
  'network/fetchAll',
  async () => {
    const response = await httpClient.post(
      '/service/rest/networkConfiguration'
    );
    return response.data.data;
  }
);

export const fetchConfigById = createAsyncThunk(
  'network/fetchOne',
  async (id: string) => {
    const response = await httpClient.get(
      `/service/rest/networkConfiguration/${id}`
    );
    return response.data;
  }
);

export const updateConfig = createAsyncThunk(
  'network/createOne',
  async (config: NetworkConfig) => {
    const response = await httpClient.post(
      '/service/rest/networkConfiguration/modify',
      config
    );
    return response.data;
  }
);

export const deleteConfig = createAsyncThunk(
  'network/deleteOne',
  async (id: number) => {
    await httpClient.delete(`/service/rest/networkConfiguration/${id}/delete`);
    return id;
  }
);

const networkConfigsSlice = createSlice({
  name: 'network',
  initialState: networkConfigsAdapter.getInitialState({
    // 'idle' | 'loading' | 'error' | 'succeeded' | 'updating' | 'deleting'
    status: 'idle',
    error: {},
  }),
  reducers: {},
  extraReducers(builder) {
    builder
      // fetch all
      .addCase(fetchNetworkConfigs.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchNetworkConfigs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        networkConfigsAdapter.setAll(state, action.payload);
      })
      .addCase(fetchNetworkConfigs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      })
      // fetch one
      .addCase(fetchConfigById.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchConfigById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        networkConfigsAdapter.upsertOne(state, action.payload);
      })
      .addCase(fetchConfigById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      })
      // create / modify one
      .addCase(updateConfig.pending, state => {
        state.status = 'updating';
      })
      .addCase(updateConfig.fulfilled, (state, action) => {
        state.status = 'succeeded';
        networkConfigsAdapter.upsertOne(state, action.payload);
      })
      // deleting
      .addCase(deleteConfig.pending, state => {
        state.status = 'deleting';
      })
      .addCase(deleteConfig.fulfilled, (state, action) => {
        state.status = 'succeeded';
        networkConfigsAdapter.removeOne(state, action.payload);
      })
      .addCase(deleteConfig.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

export default networkConfigsSlice.reducer;

// memoised selectors
export const {
  selectAll: selectAllNetworkConfigs,
  selectById: selectNetworkConfigById,
} = networkConfigsAdapter.getSelectors<RootState>(state => state.network);
