/* eslint no-param-reassign: off */
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import httpClient from 'utilities/httpClient';

export interface Location {
  city: string;
  country: string;
  description: string;
  houseNumber: string;
  id: number;
  isDefault: boolean;
  organization___id: number;
  postcode: string;
  street: string;
  timeCreated?: number;
  latitude: number;
  longitude: number;
}

const locationsAdapter = createEntityAdapter<Location>({
  sortComparer: (a, b) => (a.id > b.id ? -1 : 1),
});

export const fetchLocations = createAsyncThunk(
  'location/fetchAll',
  async () => {
    const response = await httpClient.get('/service/rest/location');
    return response.data;
  }
);

export const modifyLocation = createAsyncThunk(
  'location/modifyOneLocation',
  async location => {
    const response = await httpClient.post(
      '/service/rest/location/modify',
      location
    );
    return response.data;
  }
);

export const deleteLocation = createAsyncThunk(
  'location/deleteOneLocation',
  async (id: number) => {
    await httpClient.delete(`/service/rest/location/${id}`);
    return Number(id);
  }
);

const locationsSlice = createSlice({
  name: 'location',
  initialState: locationsAdapter.getInitialState({
    // 'idle' | 'loading' | 'error' | 'succeeded' | 'updating' | 'deleting'
    status: 'idle',
    error: {},
  }),
  reducers: {},
  extraReducers(builder) {
    builder
      // fetch all
      .addCase(fetchLocations.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        state.status = 'succeeded';
        locationsAdapter.setAll(state, action.payload);
      })
      .addCase(fetchLocations.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      })
      // create / modify one
      .addCase(modifyLocation.pending, state => {
        state.status = 'updating';
      })
      .addCase(modifyLocation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        locationsAdapter.upsertOne(state, action.payload);
      })
      .addCase(modifyLocation.rejected, state => {
        state.status = 'failed';
      })
      // deleting
      .addCase(deleteLocation.pending, state => {
        state.status = 'deleting';
      })
      .addCase(deleteLocation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        locationsAdapter.removeOne(state, action.payload);
      })
      .addCase(deleteLocation.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

export default locationsSlice.reducer;

// memoised selectors
export const { selectAll: selectAllLocations, selectById: selectLocationById } =
  locationsAdapter.getSelectors<RootState>(state => state.locations);
