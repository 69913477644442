import { useEffect, useCallback, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { debounce } from 'lodash';
import httpClient from './httpClient';

// inspiration from https://usehooks.com/useLocalStorage/

function useBackendTableColumnsPreference(key, initialValue, showPageNumber) {
  const currentUser = useAppSelector(state => state.auth.current);

  const [storedValue, setStoredValue] = useState(initialValue);
  const [tableId, setTableId] = useState(-1);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchColumnData = async () => {
      try {
        const response = await httpClient.get(
          `/service/rest/accountFilterPreferences/WEB/${key}`
        );
        const parsedResponse = JSON.parse(response.data.preferenceData);

        let preferenceData = parsedResponse;

        // column options 👇
        if (!Array.isArray(initialValue)) {
          if (!showPageNumber) preferenceData = { ...preferenceData, page: 0 };
          // loop through initial column preferences
          Object.entries(initialValue.columnVisibility).forEach(
            ([localColumnKey]) => {
              // check if current local column exists on server
              const columnExists = Object.entries(
                parsedResponse.columnVisibility
              ).some(([serverColumnKey]) => serverColumnKey === localColumnKey);
              // if not, add it
              if (!columnExists) {
                preferenceData.columnVisibility = {
                  ...preferenceData.columnVisibility,
                  [localColumnKey]: { visible: true, default: true },
                };
              }
            }
          );
        } else {
          // column order 👇
          initialValue.forEach(localColumn => {
            const columnExists = parsedResponse.some(
              serverColumn => serverColumn === localColumn
            );
            // if new column is not on server, reset order to default
            if (!columnExists) {
              preferenceData = initialValue;
            }
          });
        }

        setTableId(response.data.id);
        setStoredValue(preferenceData);
      } catch (err) {
        console.log('Error is: ', err, ' key: ', key);
      }
      setIsLoaded(true);
    };

    fetchColumnData();
  }, []);

  const updateTableColumnsOptions = useCallback(
    debounce(async (value, tableIdArg) => {
      try {
        const newObjectData = {
          account___id: currentUser.id,
          preferenceData: JSON.stringify(value),
          preferenceTable: key,
          preferenceType: 'WEB',
          timeCreated: null,
        };
        if (tableIdArg !== -1) {
          newObjectData.id = tableIdArg;
        }

        const response = await httpClient.post(
          '/service/rest/accountFilterPreferences/modify',
          newObjectData
        );

        setTableId(response.data.id);
      } catch (error) {
        console.error(error);
      }
    }, 1000),
    [key]
  );

  const setValue = value => {
    setStoredValue(value);

    updateTableColumnsOptions(value, tableId);
  };
  return [storedValue, setValue, isLoaded];
}

export default useBackendTableColumnsPreference;
