/* eslint no-param-reassign: off */
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import httpClient from 'utilities/httpClient';

// export interface Device {
//   id: number;
//   timeCreated: number;
//   mac: string;
//   name: string;
//   latitude: number;
//   longitude: number;
//   locationAddress: string;
//   timeLastSeen: number;
//   metadata: {
//     // todo: add actual sleepmode types here
//     sleepMode: 'FIXED_INTERVAL';
//     // todo: add actual device Profile types here
//     deviceProfile: 'USB';
//     triggerExpiry: number;
//     heartbeatSchedule: number;
//     precisionImprovementInterval: number;
//     snoozeDuration: number;
//     requiredAccuracy: number;
//     deviceOnline: boolean;
//     deviceSerialNumber: number;
//   };
//   event: {
//     first_device_message: boolean;
//     ble_power_on_counter: number;
//     temperature: number;
//     humidity: number;
//     pressure: number;
//     battery: number;
//     timestamp: number;
//   };
//   // todo: add actual device state types here
//   state: 'NOT SEEN';
//   // todo: add actual device configuration type here
//   configurations: any[];
//   deviceType___id: number;
//   deviceType___name: string;
//   hardware___id: number;
//   organization___id: number;
//   networkConfiguration___id: number;
//   networkConfiguration___name: string;
//   deleted: boolean;
// }

//  "deviceId": 46,
//     "deviceName": "NIKOLA VARAZDIN 22",
//     "mac": "AAAAD1F9A08D",
//     "rssi": "",
//     "state": "NOT SEEN",
//     "lastSeen": 1615887469000,
//     "longitude": null,
//     "latitude": null,
//     "defaultLongitude": -5.78,
//     "defaultLatitude": 40.751,
//     "batteryVoltage": null,
//     "modemSignalData": null,
//     "iccidData": "{\"simIccidInfo\":\"8938599201910132822F\\u0000\"}",
//     "organizationId": 63

const devicesAdapter = createEntityAdapter({
  sortComparer: (a, b) => (a.id > b.id ? -1 : 1),
});

export const fetchAed = createAsyncThunk('device/fetchAedData', async () => {
  const response = await httpClient.post('/web/dashboard/aed', {
    take: 100,
  });
  return response.data.data;
});

export const fetchOneAed = createAsyncThunk(
  'device/fetchOnlyOneAed',
  async deviceId => {
    const response = await httpClient.post('/web/dashboard/aed', {
      filter: {
        filters: [
          {
            field: 'id',
            operator: 'eq',
            value: Number(deviceId),
          },
        ],
        logic: 'and',
      },
      take: 1,
    });
    return response.data.data[0];
  }
);

export const fetchDeviceLatestEvents = createAsyncThunk(
  'device/fetchDeviceLatestEvents',
  async deviceId => {
    const response = await httpClient.get(
      `/service/rest/device-event/latestEvents/${deviceId}`
    );

    return response.data;
  }
);

export const modifyDevice = createAsyncThunk(
  'device/modifyDevice',
  async ({
    id,
    deviceName,
    defaultLatitude,
    defaultLongitude,
    organizationId,
  }) => {
    await httpClient.post(`/web/device/${id}/update`, {
      name: deviceName,
      defaultLatitude,
      defaultLongitude,
    });
    const responseOrganization = await httpClient.post(
      `/web/device/${id}/organization`,
      {
        organization: organizationId,
      }
    );

    return responseOrganization.data;
  }
);

export const fetchMapDataWithLatestEvents = createAsyncThunk(
  'device/fetchMapDataWithLatestEvents',
  async () => {
    const response = await httpClient.get(
      `/web/dashboard/map/withLatestEvents`
    );
    return response.data;
  }
);

export const setDeviceReady = createAsyncThunk(
  'device/setDeviceReady',
  async ({ deviceId }) => {
    await httpClient.put(
      `/service/rest/device/state/updateToReady/${deviceId}`,
      {
        take: 100,
      }
    );

    return deviceId;
  }
);

export const updateDeviceConfigurationData = createAsyncThunk(
  'device/updateDeviceConfigurationData',
  async deviceData => {
    const response = await httpClient.put(
      `/service/rest/device/update`,
      deviceData
    );
    return response.data;
  }
);

const devicesSlice = createSlice({
  name: 'device',
  initialState: devicesAdapter.getInitialState({
    error: {},
    isDeviceReadyLoading: false,
    modifying: false,
    aedLatestEvents: { data: [], fetching: false },
    aeds: { data: [], fetching: false },
    mapLatestEvents: {
      data: [],
      fetching: false,
    },
  }),
  reducers: {},
  extraReducers(builder) {
    builder
      // fetch all aeds
      .addCase(fetchAed.pending, state => {
        state.aeds.fetching = true;
      })
      .addCase(fetchAed.fulfilled, (state, action) => {
        state.aeds.fetching = false;
        state.aeds.data = action.payload.sort((a, b) => (b.id > a.id ? 1 : -1));
      })
      .addCase(fetchAed.rejected, (state, action) => {
        state.aeds.fetching = false;
        state.error = action.error;
      })
      // fetch only one aeds
      .addCase(fetchOneAed.pending, state => {
        state.aeds.fetching = true;
      })
      .addCase(fetchOneAed.fulfilled, (state, action) => {
        state.aeds.fetching = false;

        if (state.aeds.data.length) {
          const index = state.aeds.data
            .slice()
            .findIndex(device => device.id === action.payload.id);
          state.aeds.data[index] = action.payload;
        } else {
          state.aeds.data.push(action.payload);
        }
      })
      .addCase(fetchOneAed.rejected, (state, action) => {
        state.aeds.fetching = false;
        state.error = action.error;
      })
      // fetch only one aed with latest events
      .addCase(fetchDeviceLatestEvents.pending, state => {
        state.aedLatestEvents.fetching = true;
      })
      .addCase(fetchDeviceLatestEvents.fulfilled, (state, action) => {
        state.aedLatestEvents.fetching = false;

        state.aedLatestEvents.data = action.payload;
      })
      .addCase(fetchDeviceLatestEvents.rejected, (state, action) => {
        state.aedLatestEvents.fetching = false;
        state.error = action.error;
      })
      // modify device
      .addCase(modifyDevice.pending, state => {
        state.modifying = true;
      })
      .addCase(modifyDevice.fulfilled, (state, action) => {
        state.modifying = false;

        const index = state.aeds.data
          .slice()
          .findIndex(device => device.id === action.payload.id);

        //! set device response, but keep old event and state data - these are not returned in the response
        state.aeds.data[index] = {
          ...action.payload,
          event: state.aeds.data[index].event,
          state: state.aeds.data[index].state,
        };
      })
      .addCase(modifyDevice.rejected, (state, action) => {
        state.modifying = false;
        state.error = action.error;
      })
      // map data with latest events
      .addCase(fetchMapDataWithLatestEvents.pending, state => {
        state.mapLatestEvents.fetching = true;
      })
      .addCase(fetchMapDataWithLatestEvents.fulfilled, (state, action) => {
        state.mapLatestEvents.fetching = false;
        state.mapLatestEvents.data = action.payload;
      })
      .addCase(fetchMapDataWithLatestEvents.rejected, (state, action) => {
        state.mapLatestEvents.fetching = false;
        state.error = action.error;
      })
      // set device ready
      .addCase(setDeviceReady.pending, state => {
        state.isDeviceReadyLoading = true;
      })
      .addCase(setDeviceReady.fulfilled, (state, action) => {
        state.isDeviceReadyLoading = false;
        state.aeds.data.find(aed => aed.id === action.payload).state = 'READY';
      })
      .addCase(setDeviceReady.rejected, state => {
        state.isDeviceReadyLoading = false;
      })
      // update device configuration data
      .addCase(updateDeviceConfigurationData.pending, state => {
        state.modifying = true;
      })
      .addCase(updateDeviceConfigurationData.fulfilled, (state, action) => {
        state.modifying = false;

        const index = state.aeds.data
          .slice()
          .findIndex(device => device.id === action.payload.id);

        state.aeds.data[index] = {
          ...action.payload,
          event: state.aeds.data[index].event,
          state: state.aeds.data[index].state,
        };
      })
      .addCase(updateDeviceConfigurationData.rejected, state => {
        state.modifying = false;
      });
  },
});

export default devicesSlice.reducer;

// memoised selectors
export const { selectAll: selectAllDevices, selectById: selectDeviceById } =
  devicesAdapter.getSelectors(state => state.devices);
