import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { Suspense, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import { fetchUnreadAlertsCount } from 'redux/slices/alerts';
import styled from 'styled-components';
import ScrollHide from '../../utilities/ScrollHide';
import Loader from '../shared/Loader';
import Sidebar from './Sidebar';
import Topbar from './Topbar';

const Content = styled.main<{ smallScreen: boolean; midScreen: boolean }>`
  flex-grow: 1;
  margin-top: 64px;
  padding: 2rem 3rem;
  width: calc(100% - 120px);
  ${({ smallScreen }) =>
    smallScreen ? `padding: 2rem 0.5rem;` : `padding: 2rem 3rem;`}
  margin-left: ${({ midScreen }) => (midScreen ? '0' : '120px')};
`;

type Breadcrumb = {
  title: string;
  href: string;
};

interface LayoutProps {
  breadcrumbs: Breadcrumb[];
  goBack: () => void;
}

const Layout: React.FC<LayoutProps> = ({ breadcrumbs, children, goBack }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchUnreadAlertsCount());
  }, [dispatch, location]);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const toggleSidebarOpen = () => setSidebarOpen(prev => !prev);

  // small screen minimises the amount of padding on main content
  const smallScreen = useMediaQuery('(max-width: 650px)');
  // mid-screen determines the type of sidebar to show
  const midScreen = useMediaQuery('(max-width: 850px)');

  const topBarProps = {
    breadcrumbs,
    toggleSidebarOpen,
    midScreen,
    smallScreen,
    goBack,
  };

  return (
    <div style={{ display: 'flex' }}>
      <Suspense fallback={<Loader />}>
        {smallScreen ? (
          <ScrollHide>
            <Topbar {...topBarProps} />
          </ScrollHide>
        ) : (
          <Topbar {...topBarProps} />
        )}
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Sidebar
          open={sidebarOpen}
          toggleOpen={toggleSidebarOpen}
          midScreen={midScreen}
          location={location.pathname}
        />
      </Suspense>
      <Content smallScreen={smallScreen} midScreen={midScreen}>
        {children}
      </Content>
    </div>
  );
};

export default Layout;
