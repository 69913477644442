import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { MdDragIndicator } from 'react-icons/md';
import styled from 'styled-components';

const Dragger = styled.span<{ shouldShow: boolean }>`
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ shouldShow }) => (shouldShow ? 1 : 0)};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.7rem;
  font-size: 1.2rem;
  cursor: grab;
  color: rgba(0, 0, 0, 0.4);
`;

interface ColumnRowProps {
  label: string;
  onToggle: () => void;
  isChecked: boolean;
  index: number;
}

const ColumnRow: React.FC<ColumnRowProps> = ({
  label,
  isChecked,
  onToggle,
  index,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*// @ts-ignore*/}
      <Draggable
        key={Number(index)}
        draggableId={String(index)}
        index={Number(index)}
      >
        {(providedDraggable, snapshotDraggable): JSX.Element => (
          <div ref={providedDraggable.innerRef}>
            <>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/*// @ts-ignore*/}
              <Box
                display="flex"
                alignItems="center"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                borderRadius="5px"
                {...providedDraggable.draggableProps}
                bgcolor={
                  isHovered || snapshotDraggable.isDragging
                    ? '#f0f0f0'
                    : 'transparent'
                }
              >
                <Dragger
                  shouldShow={isHovered}
                  {...providedDraggable.dragHandleProps}
                >
                  <MdDragIndicator />
                </Dragger>
                <FormControlLabel
                  control={<Checkbox checked={isChecked} onChange={onToggle} />}
                  label={<>{label}</>}
                />
              </Box>{' '}
            </>
          </div>
        )}
      </Draggable>
    </>
  );
};

export default ColumnRow;
