// import { Paper } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import { t } from '@lingui/macro';
import { MdError } from 'react-icons/md';
import styled from 'styled-components';

const Container = styled.div`
  padding: 4rem;
  margin: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: calc(100vh - 108px);

  svg {
    font-size: 120px;
    color: ${props => props.theme.palette.divider};
    margin: 64px 0;
  }

  h5 {
    color: ${props => props.theme.palette.text.secondary};
    font-size: 24px;
    font-weight: 400;
    margin: 0;
    margin-bottom: 128px;
    line-height: 1.5;
  }
`;

const ErrorPage = ({ notFound }) => {
  const theme = useTheme();

  return (
    <Container theme={theme}>
      <MdError />
      {notFound ? (
        <h5>{t`page_not_found`}</h5>
      ) : (
        <h5>{t`There was a problem rendering the page`}</h5>
      )}
    </Container>
  );
};

export default ErrorPage;
