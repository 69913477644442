import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const ScrollHide = ({ children }) => {
  const scrollRef = useRef();
  const [isDownScroll, setIsDownScroll] = useState(false);
  const [isLowEnough, setIsLowEnough] = useState(false);

  const checkScroll = () => {
    if (scrollRef.current < window.scrollY) {
      setIsDownScroll(true);
    } else {
      setIsDownScroll(false);
    }
    if (window.scrollY > 60) {
      setIsLowEnough(true);
    } else {
      setIsLowEnough(false);
    }
    scrollRef.current = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScroll);
    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, [isDownScroll]);

  if (!isLowEnough)
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          transition: '0.2s all ease-in-out',
          width: '100vw',
          zIndex: 99,
        }}
      >
        {children}
      </div>
    );
  return isDownScroll ? (
    <div
      style={{
        transition: '0.2s all ease-in-out',
        position: 'fixed',
        top: -120,
        zIndex: 99,
        width: '100vw',
      }}
    >
      {children}
    </div>
  ) : (
    <div
      style={{
        transition: '0.2s all ease-in-out',
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 99,
      }}
    >
      {children}
    </div>
  );
};

ScrollHide.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ScrollHide;
