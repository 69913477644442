import React from 'react';
import ReactDOM from 'react-dom';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import * as plurals from 'make-plural/plurals';
import { Provider } from 'react-redux';

import { messages as de } from './locales/de/messages';
import { messages as en } from './locales/en/messages';

import './index.css';
import store from './redux/store';
import App from './App';

// load languages
i18n.loadLocaleData('de', { plurals: plurals.de });
i18n.load('de', de);
i18n.loadLocaleData('en', { plurals: plurals.en });
i18n.load('en', en);

i18n.activate('en');

ReactDOM.render(
  <I18nProvider i18n={i18n}>
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/*// @ts-ignore*/}
    <Provider store={store}>
      <App />
    </Provider>
  </I18nProvider>,
  document.getElementById('root')
);
