import styled from 'styled-components';
import { List, Theme } from '@material-ui/core';

export const Container = styled.div`
  width: 120px;
  height: 100%;
  margin: 0;
  overflow-y: auto;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const StyledList = styled(List)<{ theme: Theme }>`
  background: ${props => props.theme.palette.background.paper};
  padding-top: 0;
  padding-bottom: 0;

  .listItem {
    padding-top: 0;
    padding-bottom: 0;
  }

  .listItemActive {
    background: #cbf3e8;
  }

  .listBox {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: none;
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
    min-height: 96px;
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 16px;
    &:hover {
      border-radius: 0;
    }
    .MuiButton-label {
      flex-direction: column;
      color: ${props => props.theme.palette.text.secondary};
    }
  }

  .listItemText {
    text-transform: none;
  }

  .listIcon {
    color: ${props => props.theme.palette.text.secondary};
    font-size: 18px;
    height: 15px;
    padding-bottom: 30px;
  }
`;
