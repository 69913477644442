import { Suspense, lazy } from 'react';
import { DashboardProvider } from 'components/shared/DashboardContext';
import { AlertsProvider } from 'components/shared/AlertsContext';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ErrorBoundary from 'components/shared/ErrorBoundary';
import Layout from './components/layout';
import Loader from './components/shared/Loader';
import Customers from './components/pages/Organizations';
const CustomerDetails = lazy(() =>
  import('components/pages/Organizations/OrganizationDetails')
);

const Rfids = lazy(() => import('./components/pages/Rfid/index'));
const RfidsDetails = lazy(() => import('./components/pages/Rfid/RfidDetails'));
const RfidsForm = lazy(() =>
  import('./components/pages/Rfid/RfidDetails/RfidForm')
);
// TODO: Auth and DRY
// creating this programatically from an array of values? useSelector hook for auth lookup

const NotFound = lazy(() => import('./components/pages/NotFound'));
const Alerts = lazy(() => import('./components/pages/Alerts'));
const Devices = lazy(() => import('./components/pages/Devices'));
const DeviceConfig = lazy(() => import('./components/pages/Devices/Details'));
const NetworkForm = lazy(() =>
  import('./components/pages/Settings/Network/NetworkForm')
);
const Accounts = lazy(() => import('./components/pages/Users'));
const AccountForm = lazy(() => import('./components/pages/Users/AccountForm'));

const Settings = lazy(() => import('./components/pages/Settings'));
const QuestionsAndAnswers = lazy(() => import('./components/pages/Q&A'));

const PositionDetails = lazy(() =>
  import(
    'components/pages/Organizations/OrganizationDetails/Positions/PositionDetails'
  )
);

export const RouteWrapper = ({ children }) => (
  <Suspense fallback={<Loader />}>
    <ErrorBoundary>{children}</ErrorBoundary>
  </Suspense>
);

const Routes = () => (
  <Router>
    <Switch>
      <Route
        exact
        path="/"
        render={() => (
          <Layout
            breadcrumbs={[
              {
                title: 'Alerts',
                href: '/',
              },
            ]}
          >
            <RouteWrapper>
              <AlertsProvider>
                <Alerts />
              </AlertsProvider>
            </RouteWrapper>
          </Layout>
        )}
      />
      <Route
        exact
        path="/alerts"
        render={() => (
          <Layout
            breadcrumbs={[
              {
                title: 'Alerts',
                href: '/alerts',
              },
            ]}
          >
            <RouteWrapper>
              <AlertsProvider>
                <Alerts />
              </AlertsProvider>
            </RouteWrapper>
          </Layout>
        )}
      />
      <Route
        exact
        path="/devices"
        render={() => (
          <Layout
            breadcrumbs={[
              {
                title: 'Devices',
                href: '/devices',
              },
            ]}
          >
            <RouteWrapper>
              <DashboardProvider>
                <Devices />
              </DashboardProvider>
            </RouteWrapper>
          </Layout>
        )}
      />
      <Route
        path="/devices/edit/:id"
        render={props => (
          <Layout
            breadcrumbs={[
              {
                title: 'Devices',
                href: '/devices',
              },
              {
                title: 'Edit Device',
                href: '/devices/edit',
              },
            ]}
          >
            <RouteWrapper>
              {/* passing on props here is necessary to get the url params */}
              <AlertsProvider>
                <DeviceConfig isEdit {...props} />
              </AlertsProvider>
            </RouteWrapper>
          </Layout>
        )}
      />
      <Route
        exact
        path="/settings"
        render={() => (
          <Layout
            breadcrumbs={[
              {
                title: 'Settings',
                href: '/settings',
              },
            ]}
          >
            <RouteWrapper>
              <Settings />
            </RouteWrapper>
          </Layout>
        )}
      />
      <Route
        exact
        path="/settings/network/add"
        render={() => (
          <Layout
            breadcrumbs={[
              {
                title: 'Network',
                href: '/settings',
              },
              {
                title: 'Add Configuration',
                href: '/settings/network/add',
              },
            ]}
          >
            <RouteWrapper>
              <NetworkForm />
            </RouteWrapper>
          </Layout>
        )}
      />
      <Route
        path="/settings/network/edit/:configId"
        render={props => (
          <Layout
            breadcrumbs={[
              {
                title: 'Network',
                href: '/settings',
              },
              {
                title: 'Edit Configuration',
                href: '/settings/network/edit',
              },
            ]}
          >
            <RouteWrapper>
              {/* passing on props here is necessary to get the url params */}
              <NetworkForm isEdit {...props} />
            </RouteWrapper>
          </Layout>
        )}
      />
      <Route
        exact
        path="/customers"
        render={props => (
          <Layout
            breadcrumbs={[
              {
                title: 'Customers',
                href: '/customers',
              },
            ]}
          >
            <RouteWrapper>
              {/* passing on props here is necessary to get the url params */}
              <Customers />
            </RouteWrapper>
          </Layout>
        )}
      />
      <Route
        exact
        path="/customer/add"
        render={props => (
          <Layout
            breadcrumbs={[
              {
                title: 'Customers',
                href: '/customers',
              },
              {
                title: 'Add customer',
                href: '/customer/add',
              },
            ]}
          >
            <RouteWrapper>
              {/* passing on props here is necessary to get the url params */}
              <CustomerDetails />
            </RouteWrapper>
          </Layout>
        )}
      />
      <Route
        path="/customers/edit/:id"
        render={props => (
          <Layout
            breadcrumbs={[
              {
                title: 'Customers',
                href: '/customers',
              },
              {
                title: 'Edit customer',
                href: '/customers/edit',
              },
            ]}
          >
            <RouteWrapper>
              {/* passing on props here is necessary to get the url params */}
              <CustomerDetails isEdit {...props} />
            </RouteWrapper>
          </Layout>
        )}
      />
      <Route
        exact
        path="/customers/edit/:id/:orgId"
        render={props => (
          <Layout
            breadcrumbs={[
              {
                title: 'Customers',
                href: '/customers',
              },
              {
                title: 'Edit customer',
                href: `/customers/edit/${props.match.params.id}`,
              },
            ]}
          >
            <RouteWrapper>
              {/* passing on props here is necessary to get the url params */}
              <PositionDetails isEdit {...props} />
            </RouteWrapper>
          </Layout>
        )}
      />
      <Route
        exact
        path="/users"
        render={() => (
          <Layout
            breadcrumbs={[
              {
                title: 'Users',
                href: '/users',
              },
            ]}
          >
            <RouteWrapper>
              <Accounts />
            </RouteWrapper>
          </Layout>
        )}
      />
      <Route
        path="/user/add"
        render={props => (
          <Layout
            breadcrumbs={[
              {
                title: 'Users',
                href: '/users',
              },
              {
                title: 'Add User',
                href: '/user/add',
              },
            ]}
          >
            <RouteWrapper>
              <AccountForm {...props} />
            </RouteWrapper>
          </Layout>
        )}
      />
      <Route
        path="/user/edit/:accountId"
        render={props => (
          <Layout
            breadcrumbs={[
              {
                title: 'Users',
                href: '/users',
              },
              {
                title: 'Edit User',
                href: '/user/edit',
              },
            ]}
          >
            <RouteWrapper>
              {/* passing on props here is necessary to get the url params */}
              <AccountForm isEdit {...props} />
            </RouteWrapper>
          </Layout>
        )}
      />
      <Route
        exact
        path="/user/edit"
        render={() => (
          <Layout
            breadcrumbs={[
              {
                title: 'Edit personal user',
                href: '/user/edit',
              },
            ]}
          >
            <RouteWrapper>
              <AccountForm isEdit />
            </RouteWrapper>
          </Layout>
        )}
      />
      {/*  rfid  start  */}
      <Route
        exact
        path="/rfids"
        render={props => (
          <Layout
            breadcrumbs={[
              {
                title: 'RFIDS',
                href: '/rfid',
              },
            ]}
          >
            <RouteWrapper>
              {/* passing on props here is necessary to get the url params */}
              <Rfids />
            </RouteWrapper>
          </Layout>
        )}
      />
      <Route
        exact
        path="/rfid/add"
        render={props => (
          <Layout
            breadcrumbs={[
              {
                title: 'RFID',
                href: '/rfids',
              },
              {
                title: 'Add RFID',
                href: '/rfid/add',
              },
            ]}
          >
            <RouteWrapper>
              {/* /!* passing on props here is necessary to get the url params *!/ */}
              <RfidsForm {...props} />
            </RouteWrapper>
          </Layout>
        )}
      />
      <Route
        path="/rfid/edit/:id"
        render={props => (
          <Layout
            breadcrumbs={[
              {
                title: 'RFID',
                href: '/rfids',
              },
              {
                title: 'Edit RFID',
                href: '/rfid/edit',
              },
            ]}
          >
            <RouteWrapper>
              {/* passing on props here is necessary to get the url params */}
              <RfidsForm isEdit {...props} />
            </RouteWrapper>
          </Layout>
        )}
      />
      {/*  rfid  end  */}
      <Route
        exact
        path="/questions"
        render={() => (
          <Layout
            breadcrumbs={[
              {
                title: 'Questions and answers',
                href: '/',
              },
            ]}
          >
            <RouteWrapper>
              <QuestionsAndAnswers />
            </RouteWrapper>
          </Layout>
        )}
      />
      <Route
        render={() => (
          <Layout
            breadcrumbs={[
              {
                title: 'Devices',
                href: '/',
              },
            ]}
          >
            <Suspense fallback={<Loader />}>
              <NotFound />
            </Suspense>
          </Layout>
        )}
      />
    </Switch>
  </Router>
);

export default Routes;
