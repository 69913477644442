// CONFIG - enumerable values used throughout the app
// For arrays: order matters - indexes are used

export const account = {
  languages: ['English', 'German', 'Italian', 'French'],
  // role could go here (only 2 items)
  // status could go here (only 2 items)
};

export const language = [
  'US English',
  'German',
  'French',
  'Italian',
  'UK English',
];
language[255] = 'Unknown';

export const electrodeTypes = [
  'Universal Electrodes',
  'AED Plus Simulator',
  'CPRD Adult',
  'Pedi-padz II',
  'Open',
];
electrodeTypes[255] = 'Unknown';

export const network = {
  band: [
    'None',
    'B1',
    'B2',
    'B3',
    'B4',
    'B5',
    'B8',
    'B12',
    'B13',
    'B18',
    'B19',
    'B20',
    'B26',
    'B28',
  ],
  accessMode: ['GSM', 'NB1', 'CATM1'],
};

export const supportTypes = [
  'Other disability',
  'Blind/partially sighted',
  'Speech disorder',
  'Hard of hearing',
  'Reduced mobility',
  'Children',
];

export const states = [
  'READY',
  'ALERT RED',
  'ALERT ORANGE',
  'MOVED',
  'NOT SEEN',
];
