import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import FormGroup from '@material-ui/core/FormGroup';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { t } from '@lingui/macro';
import ColumnRow from './ColumnRow';

interface ColumnSettingsDialogProps {
  open: boolean;
  onClose: () => void;
  columnHeaders: any[];
  columnOrder: string[];
  onColumnOrderChange: (sorted: string[]) => void;
  onVisibilityChange: (visibility: { [key: string]: boolean }) => void;
  columnVisibility: { [key: string]: boolean };
}

const getInitialHeaders = (
  columnHeaders: any[],
  columnOrder: string[]
): any[] => {
  if (!columnHeaders) return [];
  const columns: any[] = [];
  columnOrder.forEach(accessor => {
    const foundColumn = columnHeaders.find(col => col.accessor === accessor);
    if (!foundColumn) return;
    columns.push(foundColumn);
  });
  return columns;
};

const ColumnSettingsDialog: React.FC<ColumnSettingsDialogProps> = ({
  open,
  onClose,
  columnHeaders,
  columnOrder,
  onColumnOrderChange,
  onVisibilityChange,
  columnVisibility,
}) => {
  const [columnItems, setColumnItems] = useState<any[]>(
    getInitialHeaders(columnHeaders, columnOrder)
  );

  useEffect(() => {
    setColumnItems(getInitialHeaders(columnHeaders, columnOrder));
    setVisibilityOptions(columnVisibility);
  }, [columnHeaders, columnOrder, columnVisibility]);

  // just column accessors are stored in state
  const [visibilityOptions, setVisibilityOptions] = useState(columnVisibility);

  const handleCheckChange = (column: { accessor: string }) =>
    setVisibilityOptions({
      ...visibilityOptions,
      [column.accessor]: !visibilityOptions[column.accessor],
    });

  const handleSubmit = () => {
    onClose();
    onColumnOrderChange(columnItems.map(col => col.accessor));
    onVisibilityChange(visibilityOptions);
  };

  const reorder = (
    list: any[],
    startIndex: number,
    endIndex: number
  ): any[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult): void => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items: any[] = reorder(
      columnItems,
      result.source.index,
      result.destination.index
    );

    setColumnItems(items);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="show-hide-columns"
      scroll="paper"
    >
      <DialogTitle id="show-hide-columns">{t`Column settings`}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {t`Choose which columns will be visible and in what order.`}
        </DialogContentText>
        <FormGroup style={{ display: 'block' }}>
          {columnItems && (
            <>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/*// @ts-ignore*/}
              <DragDropContext onDragEnd={onDragEnd}>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/*// @ts-ignore*/}
                <Droppable droppableId="droppable">
                  {provided => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {columnItems.map((column, index) => (
                        <ColumnRow
                          key={column.accessor}
                          label={column.Header}
                          index={index}
                          onToggle={() => handleCheckChange(column)}
                          isChecked={visibilityOptions[column.accessor]}
                        />
                      ))}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          )}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {t`Cancel`}
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {t`Apply`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ColumnSettingsDialog;
