import styled from 'styled-components';
import { AppBar, Toolbar, Breadcrumbs } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const StyledAppBar = styled(AppBar)`
  z-index: 1;
  box-shadow: none;
  border-bottom: 1px solid ${props => props.bordercolor};
  border-left: 1px solid ${props => props.bordercolor};
  width: ${props => (props.midScreen ? '100%' : 'calc(100% - 118px)')};
  background: transparent;
`;

export const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: center;
  padding-right: 24px;
  background: ${props => props.bgcolor};
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  min-width: 200px;
  text-align: center;
  position: absolute;
  left: 2rem;
  .MuiBreadcrumbs-ol {
    justify-content: flex-start;
  }
`;

export const IconsWrapper = styled.div`
  color: ${props => props.color};
`;

export const StyledLink = styled(Link)`
  font-size: 1.3rem;
  text-decoration: none;
  color: ${props => props.color};
  display: inline-block;
  transition: 0.2s;
  font-weight: 400;
  &:hover {
    color: #777;
  }
`;

export const Title = styled.h3`
  font-size: 1.3rem;
  display: inline-block;
  font-weight: 400;
  color: #232323;
`;

export const StyledHeading = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0.75px;
  color: #36d4a9;
  text-align: center;
  span {
    font-weight: 100;
    color: #67818e;
  }
`;
