import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { t } from '@lingui/macro';
import { toast } from 'react-toastify';
import CommonTable from 'components/shared/CommonTable';
import { deleteOrganization, Organization } from 'redux/slices/organizations';
import columnCreator from 'utilities/columnCreator';
import CheckDeleteDialog from 'components/shared/CheckDeleteDialog';
import columns from './columns';

interface OrganizationTableProps {
  organizations: Organization[];
  status: string;
  tableRefresh: () => void;
  onAddOrg: () => void;
  onEdit: (id: number) => void;
}

const OrganizationsTable: React.FC<OrganizationTableProps> = ({
  organizations,
  status,
  tableRefresh,
  onAddOrg,
  onEdit,
}) => {
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(state => state.auth.current);

  const fetching = status === 'loading';

  const columnHeaders = columns.map(column => columnCreator({ ...column }));

  const deleteSelectedOrganization = async () => {
    try {
      if (deleteId) {
        await dispatch(deleteOrganization(deleteId)).unwrap();
        toast.success(t`Organization successfully deleted`);
        setDeleteId(null);
      } else {
        throw new Error();
      }
    } catch (err) {
      toast.error(t`There was a problem deleting the organization`);
      console.log(`Error is: ${err}`);
    }
  };

  return (
    <>
      <CommonTable
        id="organizations"
        title={t`Customers`}
        data={organizations}
        columnHeaders={columnHeaders}
        onRefresh={tableRefresh}
        loading={fetching}
        onAdd={onAddOrg}
        onEdit={onEdit}
        onDelete={setDeleteId}
        customActions={[]}
      />
      {deleteId ? (
        <CheckDeleteDialog
          deleteId={deleteId}
          onClose={() => setDeleteId(null)}
          onDelete={deleteSelectedOrganization}
          isDeleting={status === 'deleting'}
        />
      ) : null}
    </>
  );
};

export default OrganizationsTable;
