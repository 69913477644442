import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import React from 'react';
import SettingsMenu from './SettingsMenu';
import {
  IconsWrapper,
  StyledAppBar,
  StyledBreadcrumbs,
  StyledHeading,
  StyledLink,
  StyledToolbar,
  Title,
} from './Topbar.styled';
import { t } from '@lingui/macro';

export const Logo = () => (
  <StyledHeading>
    AED <span>trax</span>
  </StyledHeading>
);

const Topbar = ({ breadcrumbs, toggleSidebarOpen, smallScreen, midScreen }) => {
  const theme = useTheme();

  return (
    <StyledAppBar
      elevation="2"
      position="absolute"
      midScreen={midScreen}
      bordercolor={theme.palette.divider}
    >
      <StyledToolbar bgcolor={theme.palette.background.paper}>
        {midScreen ? (
          <IconsWrapper
            color={theme.palette.text.secondary}
            style={{ position: 'absolute', left: '1rem' }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleSidebarOpen}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          </IconsWrapper>
        ) : (
          <StyledBreadcrumbs aria-label="breadcrumb">
            {breadcrumbs.map((crumb, i) => {
              // console.log('crumb: ', crumb);
              if (i === breadcrumbs.length - 1)
                // todo: translate crumb title
                return <Title key={i}>{t`${crumb?.title}`}</Title>;
              return smallScreen ? (
                ''
              ) : (
                <StyledLink
                  key={i}
                  to={crumb.href}
                  color={theme.palette.text.secondary}
                >
                  {/* // todo: translate crumb title */}
                  {t`${crumb?.title}`}
                </StyledLink>
              );
            })}
          </StyledBreadcrumbs>
        )}
        <Logo />
        <IconsWrapper
          color={theme.palette.text.secondary}
          style={{ position: 'absolute', right: '1rem' }}
        >
          <SettingsMenu />
        </IconsWrapper>
      </StyledToolbar>
    </StyledAppBar>
  );
};

Topbar.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string,
    })
  ).isRequired,
  toggleSidebarOpen: PropTypes.func.isRequired,
  smallScreen: PropTypes.bool.isRequired,
  midScreen: PropTypes.bool.isRequired,
};

export default Topbar;
