/* eslint no-param-reassign: "off" */
/* eslint camelcase: "off" */
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import httpClient from 'utilities/httpClient';

export interface QuestionAndAnswer {
  answer: string;
  id: number;
  language___id: number;
  organization___id?: number;
  question: string;
  timeCreated: number;
}

const questionsAndAnswersAdapter = createEntityAdapter<QuestionAndAnswer>({
  sortComparer: (a, b) => (a.id > b.id ? -1 : 1),
});

export const fetchQuestionsAnswers = createAsyncThunk(
  'questionAndAnswer/fetchAll',
  async take => {
    const response = await httpClient.post(
      '/service/rest/questionAnswers',
      take
    );
    return response.data.data;
  }
);

export const modifyQuestionAnswer = createAsyncThunk(
  'questionAndAnswer/modifyQuestionAnswer',
  async body => {
    const response = await httpClient.post(
      `/service/rest/questionAnswers/modify`,
      body
    );
    return response.data;
  }
);

export const deleteQuestionAnswer = createAsyncThunk(
  'questionAndAnswer/deleteOne',
  async id => {
    await httpClient.delete(`/service/rest/questionAnswers/${id}/delete`);
    return Number(id);
  }
);

const questionsAndAnswersSlice = createSlice({
  name: 'questionAndAnswer',
  initialState: questionsAndAnswersAdapter.getInitialState({
    // 'idle' | 'loading' | 'error' | 'succeeded' | 'updating' | 'deleting'
    status: 'idle',
    error: {},
  }),
  reducers: {},
  extraReducers(builder) {
    builder
      // fetch all
      .addCase(fetchQuestionsAnswers.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchQuestionsAnswers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        questionsAndAnswersAdapter.setAll(state, action.payload);
      })
      .addCase(fetchQuestionsAnswers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      })
      // create / modify one
      .addCase(modifyQuestionAnswer.pending, state => {
        state.status = 'updating';
      })
      .addCase(modifyQuestionAnswer.fulfilled, (state, action) => {
        state.status = 'succeeded';
        questionsAndAnswersAdapter.upsertOne(state, action.payload);
      })
      .addCase(modifyQuestionAnswer.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      })
      // deleting
      .addCase(deleteQuestionAnswer.pending, state => {
        state.status = 'deleting';
      })
      .addCase(deleteQuestionAnswer.fulfilled, (state, action) => {
        state.status = 'succeeded';
        questionsAndAnswersAdapter.removeOne(state, action.payload);
      })
      .addCase(deleteQuestionAnswer.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

export default questionsAndAnswersSlice.reducer;

// memoised selectors
export const {
  selectAll: selectAllQuestionsAnswers,
  selectById: selectQuestionAnswerById,
} = questionsAndAnswersAdapter.getSelectors<RootState>(
  state => state.questionsAndAnswers
);
