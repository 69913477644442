/* eslint no-param-reassign: off */
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import httpClient from 'utilities/httpClient';

export interface Position {
  id: number;
  description: string;
  image: string;
  latitude: number;
  location___id: number;
  longitude: number;
  positionStorageType___id: number;
  rfidCardUid: string;
  timeCreated?: number;
}

const positionsAdapter = createEntityAdapter<Position>({
  sortComparer: (a, b) => (a.id > b.id ? -1 : 1),
});

export const fetchPositions = createAsyncThunk(
  'position/fetchAll',
  async () => {
    const response = await httpClient.get('/service/rest/position');
    return response.data;
  }
);

export const fetchPositionStorageTypes = createAsyncThunk(
  'position/fetchAllPositionStorageTypes',
  async () => {
    const response = await httpClient.get(
      '/service/rest/position-storage-type'
    );
    return response.data;
  }
);

export const modifyPosition = createAsyncThunk(
  'position/modifyOnePosition',
  async position => {
    //console.log('Position uploada data are: ', position);
    const response = await httpClient.post(
      '/service/rest/position/modify',
      position
    );
    return response.data;
  }
);

export const deletePosition = createAsyncThunk(
  'position/deleteOnePosition',
  async (id: number) => {
    await httpClient.delete(`/service/rest/position/${id}`);
    return Number(id);
  }
);

const positionsSlice = createSlice({
  name: 'position',
  initialState: positionsAdapter.getInitialState({
    // 'idle' | 'loading' | 'error' | 'succeeded' | 'updating' | 'deleting'
    status: 'idle',
    error: {},
    positionStorageTypes: {
      result: [],
      // 'idle' | 'loading' | 'error' | 'succeeded'
      statusType: 'idle',
    },
  }),
  reducers: {},
  extraReducers(builder) {
    builder
      // fetch all
      .addCase(fetchPositions.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchPositions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        positionsAdapter.setAll(state, action.payload);
      })
      .addCase(fetchPositions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      })
      .addCase(fetchPositionStorageTypes.pending, state => {
        state.positionStorageTypes.statusType = 'loading';
      })
      .addCase(fetchPositionStorageTypes.fulfilled, (state, action) => {
        state.positionStorageTypes.statusType = 'succeeded';
        //console.log('Storage type is: ', action.payload);
        state.positionStorageTypes.result = action.payload;
      })
      .addCase(fetchPositionStorageTypes.rejected, (state, action) => {
        state.positionStorageTypes.statusType = 'failed';
        state.error = action.error;
      })
      // create / modify one
      .addCase(modifyPosition.pending, state => {
        state.status = 'updating';
      })
      .addCase(modifyPosition.fulfilled, (state, action) => {
        state.status = 'succeeded';
        positionsAdapter.upsertOne(state, action.payload);
      })
      .addCase(modifyPosition.rejected, state => {
        state.status = 'failed';
      })
      // deleting
      .addCase(deletePosition.pending, state => {
        state.status = 'deleting';
      })
      .addCase(deletePosition.fulfilled, (state, action) => {
        state.status = 'succeeded';
        positionsAdapter.removeOne(state, action.payload);
      })
      .addCase(deletePosition.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

export default positionsSlice.reducer;

// memoised selectors
export const { selectAll: selectAllPositions, selectById: selectPositionById } =
  positionsAdapter.getSelectors<RootState>(state => state.positions);
