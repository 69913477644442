import { useAppSelector } from 'redux/hooks';
import Badge from '@material-ui/core/Badge';
import {
  RiAlertLine,
  RiBriefcase4Line,
  RiSettings4Line,
  RiUser3Line,
  RiQuestionAnswerLine,
  RiCustomerService2Line,
  RiBankCardLine,
} from 'react-icons/ri';
import { t } from '@lingui/macro';

const AlertsCountBadge: React.FC = () => {
  const unreadAlerts = useAppSelector(
    state => state.alerts.unreadAlertsCount || 0
  );
  return (
    <Badge badgeContent={unreadAlerts} color="secondary">
      <RiAlertLine />
    </Badge>
  );
};

const menuItems = () => [
  {
    title: t`Devices`,
    icon: <RiBriefcase4Line />,
    href: '/devices',
  },
  {
    title: t`Alerts`,
    icon: <AlertsCountBadge />,
    href: '/',
    // restrict: 'SUPER',
  },
  {
    title: t`Customers`,
    icon: <RiCustomerService2Line />,
    href: '/customers',
    restrict: '',
  },
  {
    title: t`Users`,
    icon: <RiUser3Line />,
    href: '/users',
    restrict: '',
  },
  {
    title: t`RFIDS`,
    icon: <RiBankCardLine />,
    href: '/rfids',
    restrict: '',
  },
  {
    title: t`Settings`,
    icon: <RiSettings4Line />,
    href: '/settings',
  },
  {
    title: t`QA`,
    icon: <RiQuestionAnswerLine />,
    href: '/questions',
    // restrict: 'SUPER',
  },
];

export default menuItems;
